import {
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';
import React, { memo, useEffect } from 'react';
import { IoCloudDownload, IoCloudUpload } from 'react-icons/io5';

export default memo(function ConfirmModal({
  toServer,
  toLocal,
}: {
  toServer?: React.MouseEventHandler<HTMLButtonElement>;
  toLocal?: React.MouseEventHandler<HTMLButtonElement>;
}) {
  const { isOpen, onClose, onOpen } = useDisclosure();

  const handleClick =
    (
      callback?: React.MouseEventHandler<HTMLButtonElement>
    ): React.MouseEventHandler<HTMLButtonElement> =>
    (e) => {
      onClose();
      callback?.(e);
    };

  useEffect(() => {
    onOpen();
  }, [onOpen]);

  return (
    <Modal isOpen={isOpen} onClose={() => {}}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>データの同期中に競合が発生しました</ModalHeader>
        <ModalBody>どのデータを優先しますか？</ModalBody>
        <ModalFooter>
          <HStack>
            {toServer && (
              <Button
                onClick={handleClick(toServer)}
                colorScheme="blue"
                leftIcon={<IoCloudDownload />}
              >
                サーバー
              </Button>
            )}
            {toLocal && (
              <Button
                onClick={handleClick(toLocal)}
                colorScheme="teal"
                leftIcon={<IoCloudUpload />}
              >
                ローカル
              </Button>
            )}
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
});
