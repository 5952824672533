import { Button, HStack } from '@chakra-ui/react';
import { useMyClasses } from 'app/hooks/myClasses';
import { useTitle } from 'app/hooks/title';
import hupassApi from 'app/hupassApi';
import routes from 'app/routes';
import MainContainer from 'components/common/container/MainContainer';
import Section from 'components/common/section/Section';
import SectionStack from 'components/common/section/SectionStack';
import {
  errorHandler,
  getErrorToastOptions,
  toast,
} from 'components/common/toast/toast';
import { memo, useEffect, useMemo, useState } from 'react';
import {
  IoAddCircle,
  IoChevronBack,
  IoRemoveCircle,
  IoSearch,
} from 'react-icons/io5';
import {
  Link as RouterLink,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import GradeGraph from './GradeGraph';
import Information from './information/Information';
import Memo from './memo/Memo';

const Detail = memo(function Detail() {
  useTitle('講義詳細');
  const { id } = useParams();
  const navigate = useNavigate();
  const { modal, getMyClass, enroll, unenroll, update } = useMyClasses();
  const [pending, setPending] = useState(false);
  const myClass = getMyClass(Number(id));
  const [cls, setCls] = useState(myClass?.cls);
  const isLoaded = cls !== undefined;
  const [params] = useSearchParams();
  const hasParams = useMemo(
    () => ['year', 'semester', 'period'].every((key) => params.has(key)),
    [params]
  );

  useEffect(() => {
    let ignore = false;

    if (myClass?.cls === undefined) {
      hupassApi
        .GetClass(Number(id))
        .then((res) => !ignore && setCls(res.data), errorHandler);
    }

    return () => {
      ignore = true;
    };
  }, [myClass, id]);

  return (
    <MainContainer>
      {modal}
      <HStack my={2}>
        <Button
          leftIcon={<IoChevronBack />}
          onClick={() => {
            navigate(-1);
          }}
          variant="link"
          colorScheme="blue"
          mr={'auto'}
        >
          前のページに戻る
        </Button>
        {hasParams && (
          <Button
            as={RouterLink}
            to={`${routes.search}?default=true&${params.toString()}`}
            variant="link"
            leftIcon={<IoSearch />}
          >
            他の授業を検索
          </Button>
        )}
      </HStack>
      <SectionStack>
        <Information
          cls={cls}
          myClass={myClass}
          isLoaded={isLoaded}
          update={update}
        />
        {myClass !== undefined && <Memo myClass={myClass} update={update} />}
        <GradeGraph myClass={myClass} cls={cls} />
        <Section>
          {myClass === undefined ? (
            <Button
              size="lg"
              w={'full'}
              colorScheme="blue"
              leftIcon={<IoAddCircle />}
              onClick={() => {
                setPending(true);
                toast.promise(
                  enroll(Number(id)).finally(() => {
                    setPending(false);
                  }),
                  {
                    success: { title: '授業を登録しました' },
                    loading: { title: '授業を登録中' },
                    error: (e) => ({
                      ...getErrorToastOptions(e),
                      title: '授業の登録に失敗しました',
                    }),
                  }
                );
              }}
              isDisabled={pending}
            >
              登録する
            </Button>
          ) : (
            <Button
              size="lg"
              w={'full'}
              colorScheme="red"
              leftIcon={<IoRemoveCircle />}
              onClick={() => {
                setPending(true);
                toast.promise(
                  unenroll(Number(id)).finally(() => {
                    setPending(false);
                  }),
                  {
                    success: { title: '授業を削除しました' },
                    loading: { title: '授業を削除中' },
                    error: (e) => ({
                      ...getErrorToastOptions(e),
                      title: '授業の削除に失敗しました',
                    }),
                  }
                );
              }}
              isDisabled={pending}
            >
              削除する
            </Button>
          )}
        </Section>
      </SectionStack>
    </MainContainer>
  );
});

export default Detail;
