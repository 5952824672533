import { Badge, ThemeTypings } from '@chakra-ui/react';
import { ClassInfo } from 'app/hupassApi';
import { messages } from 'app/messages';
import { getAGradeRatio, getPassRatio } from 'app/utils';
import { memo } from 'react';

export type ClassInfoBadgesProps = {
  cls?: ClassInfo;
};

export default memo(function ClassInfoBadges(props: ClassInfoBadgesProps) {
  const { cls } = props;

  const badges: Array<{ text: string; color?: ThemeTypings['colorSchemes'] }> =
    cls
      ? [
          { text: `${cls.faculty}`, color: 'blue' },
          { text: `${cls.credit}単位`, color: 'orange' },
          ...(cls?.grade_info
            ? [
                { text: `GPA ${cls.grade_info.gpa}` },
                { text: `A帯率 ${getAGradeRatio(cls)} %` },
                { text: `合格率 ${getPassRatio(cls)} %` },
              ]
            : [{ text: '成績情報なし' }]),
        ]
      : [{ text: messages.loading }];

  return (
    <>
      {badges.map(({ text, color }, index) => (
        <Badge key={index} colorScheme={color}>
          {text}
        </Badge>
      ))}
    </>
  );
});
