import { Box, UseRadioProps, useRadio } from '@chakra-ui/react';
import { PropsWithChildren, memo } from 'react';

export default memo(function ColorRadioButton(
  props: PropsWithChildren<UseRadioProps>
) {
  const { getInputProps, getRadioProps } = useRadio(props);

  return (
    <Box as="label">
      <input {...getInputProps()} />
      <Box
        {...getRadioProps()}
        bgColor={props.value}
        p={4}
        border={'4px'}
        borderColor={'transparent'}
        borderRadius={'md'}
        _checked={{ borderColor: 'chakra-body-text' }}
      >
        {props.children}
      </Box>
    </Box>
  );
});
