import { Container, type BoxProps } from '@chakra-ui/react';
import { memo } from 'react';

const MainContainer = memo(function MainContainer(props: BoxProps) {
  return (
    <Container
      as="main"
      maxW="7xl"
      py={2}
      overflowY={{ xl: 'auto' }}
      {...props}
    />
  );
});

export default MainContainer;
