import {
  Box,
  Button,
  CardBody,
  CardFooter,
  HStack,
  Heading,
  Link,
  List,
  ListItem,
  Text,
  ThemeTypings,
} from '@chakra-ui/react';
import { useTitle } from 'app/hooks/title';
import { MyClassInfo } from 'app/hupassApi';
import routes from 'app/routes';
import { ClassCard } from 'components/common/card/ClassCard';
import { DetailMemoMarkdown } from 'components/detail/memo/MarkdownMemo';
import { featureClassMemoState } from 'components/misc/Misc';
import Linkify from 'linkify-react';
import { memo } from 'react';
import { IoLocation, IoOpenOutline, IoSchool } from 'react-icons/io5';
import { Link as RouterLink } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

export type TimelineClassCardProps = {
  myClass: MyClassInfo;
};

export default memo(function TimelineClassCard(props: TimelineClassCardProps) {
  useTitle('今日の授業');
  const { myClass } = props;
  const featureClassMemo = useRecoilValue(featureClassMemoState);
  const links: Array<{
    label: React.ReactNode;
    link: string;
    color: ThemeTypings['colorSchemes'];
    isDisabled: boolean;
  }> = [
    {
      label: 'シラバス',
      link: myClass.cls.urls['syllabus'] ?? '',
      color: 'teal',
      isDisabled: !myClass.cls.cls_id,
    },
    {
      label: 'Moodle',
      link: myClass.cls.urls['moodle'] ?? '',
      color: 'orange',
      isDisabled: !myClass.cls.urls['moodle'],
    },
    {
      label: 'ELMS',
      link: myClass.cls.urls['elms'] ?? '',
      color: 'green',
      isDisabled: !myClass.cls.cls_id,
    },
  ];

  return (
    <ClassCard classColor={myClass.color}>
      <RouterLink
        to={routes.timetable_detail.replace(':id', `${myClass.cls.id}`)}
      >
        <CardBody pb={2}>
          <Box mb={4}>
            <Heading fontSize={'2xl'}>
              {myClass.c_subject ?? myClass.cls.subject}
            </Heading>
            <Text color={'chakra-subtle-text'}>
              {myClass.c_theme ?? myClass.cls.theme}
            </Text>
          </Box>
          <List spacing={2} mb={6}>
            <HStack as={ListItem}>
              <IoSchool />
              <Text>{myClass.c_teacher ?? myClass.cls.teacher}</Text>
            </HStack>
            <HStack as={ListItem}>
              <IoLocation />
              <Text>{myClass.c_place}</Text>
            </HStack>
          </List>
          <Box>
            {
              {
                text: <Linkify>{myClass.memo}</Linkify>,
                markdown: (
                  <DetailMemoMarkdown>{myClass.memo}</DetailMemoMarkdown>
                ),
                'editor.js': '',
              }[featureClassMemo]
            }
          </Box>
        </CardBody>
      </RouterLink>
      <CardFooter p={3} gap={2} flexWrap={'wrap'}>
        {links.map(({ label, link, color, isDisabled }, index) => (
          <Button
            key={index}
            as={Link}
            isExternal
            rightIcon={<IoOpenOutline />}
            href={isDisabled ? '' : link}
            isDisabled={isDisabled}
            variant="outline"
            colorScheme={color}
            size={{ base: 'sm', md: 'md' }}
          >
            {label}
          </Button>
        ))}
      </CardFooter>
    </ClassCard>
  );
});
