import {
  Box,
  Button,
  Grid,
  GridItem,
  HStack,
  Heading,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Progress,
  SimpleGrid,
  Skeleton,
  Text,
  VStack,
} from '@chakra-ui/react';
import { localStorageKeys, useJsonLocalStorage } from 'app/hooks/localStorage';
import { useMyClasses } from 'app/hooks/myClasses';
import { useTitle } from 'app/hooks/title';
import hupassApi, { type ClassInfo, type Paginated } from 'app/hupassApi';
import { messages } from 'app/messages';
import routes from 'app/routes';
import MainContainer from 'components/common/container/MainContainer';
import Section from 'components/common/section/Section';
import SectionContents from 'components/common/section/SectionContents';
import SectionHeading from 'components/common/section/SectionHeading';
import SectionText from 'components/common/section/SectionText';
import { getErrorToastDescription, toast } from 'components/common/toast/toast';
import { Suspense, useCallback, useEffect, useRef, useState } from 'react';
import {
  IoChevronBack,
  IoCloseCircle,
  IoSearchOutline,
  IoReload,
} from 'react-icons/io5';
import { useInView } from 'react-intersection-observer';
import { Link, useSearchParams } from 'react-router-dom';
import { atom, useRecoilState } from 'recoil';
import SearchClassCard from './SearchClassCard';
import SearchOptions from './SearchOptions';
import SortOptions from './SortOptions';
import { thisYear, thisSemester } from 'app/date';
import { SemesterToStr } from 'app/utils';

const searchClassesState = atom<ClassInfo[]>({
  key: 'searchClasses',
  default: [],
});

const searchPaginationState = atom<Omit<Paginated, 'results'> | undefined>({
  key: 'searchPagination',
  default: undefined,
});

let lastSearchQuery = '';

export default function Search() {
  useTitle('検索');
  const [params, setParams] = useSearchParams();
  const [keywords, setKeywords] = useState(params.get('keyword') ?? '');
  const [pagination, setPagination] = useRecoilState(searchPaginationState);
  const [classes, setClasses] = useRecoilState(searchClassesState);
  const { getMyClass, enroll, unenroll } = useMyClasses();
  const [error, setError] = useState<Error>();
  const { ref, inView } = useInView();
  const page = useRef<number>(pagination?.current_page ?? 0);
  const taskId = useRef<NodeJS.Timeout>();
  const [year] = useJsonLocalStorage(localStorageKeys.timetableYear, thisYear);
  const [semester] = useJsonLocalStorage(
    localStorageKeys.timetableSemester,
    thisSemester
  );
  const [faculties] = useJsonLocalStorage<string[]>(
    localStorageKeys.myFaculties,
    []
  );

  // クエリパラメータにデフォルトのパラメータ渡すとこ
  useEffect(() => {
    if (params.get('default') === 'true') {
      const tempParams = new URLSearchParams(params);

      // カレンダーから「年度」「学期」持ってきて設定する
      tempParams.set('year', year().toString());
      tempParams.set('semester', SemesterToStr(semester()));
      // localStorageから「学部」設定
      Object.values(faculties()).forEach((faculty: string) => {
        tempParams.append('faculty', faculty);
      });

      tempParams.delete('default');
      setParams(tempParams);
    }
  }, [params, setParams, year, semester, faculties]);

  useEffect(() => {
    let ignore = false;
    const controller = new AbortController();
    setKeywords(params.get('keyword') || '');

    if (inView) {
      setError(undefined);
      lastSearchQuery = params.toString();

      const tempParams = new URLSearchParams(params);
      tempParams.set('page', `${page.current + 1}`);

      hupassApi
        .ListClasses(tempParams, controller)
        .then((res) => {
          if (!ignore) {
            const { results, ...newPagination } = res.data;
            page.current = newPagination.current_page;
            setPagination(newPagination);
            setClasses((current) => current.concat(results));
          }
          return res.data;
        })
        .catch((e) => {
          if (!ignore) {
            setError(e as Error);
          }
        });
    } else {
      if (lastSearchQuery !== params.toString()) {
        const reset = () => {
          page.current = 0;
          setClasses([]);
          setPagination(undefined);
        };
        if (taskId.current === undefined) reset();
        taskId.current = setTimeout(() => {
          reset();
        }, 1000);
      }
    }

    return () => {
      ignore = true;
      controller.abort();
      clearTimeout(taskId.current);
    };
  }, [inView, params, setClasses, setPagination]);

  const changeKeywords = useCallback(
    (newKeywords: string) => {
      setKeywords(newKeywords);
      params.set('keyword', newKeywords);
      setParams(params);
    },
    [params, setParams]
  );

  return (
    <MainContainer>
      <HStack gap={4} py={2} mb={2} justifyContent="space-between">
        <Button
          leftIcon={<IoChevronBack />}
          as={Link}
          to={routes.timetable}
          variant="link"
          mr="auto"
          colorScheme="blue"
        >
          時間割に戻る
        </Button>
        <Button
          variant="link"
          leftIcon={<IoReload />}
          onClick={() => {
            setParams(new URLSearchParams());
            setKeywords('');
            toast({
              status: 'success',
              title: '検索条件をリセットしました',
            });
          }}
          colorScheme="red"
        >
          リセット
        </Button>
      </HStack>
      <Grid
        templateAreas={"'search search' 'sort filter'"}
        templateColumns="repeat(2, 1fr)"
        gap={4}
      >
        <GridItem area="search">
          <InputGroup variant="filled">
            <InputLeftElement>
              <IoSearchOutline />
            </InputLeftElement>
            <Input
              rounded="full"
              placeholder="講義名・学部・教授で検索"
              value={keywords}
              onChange={(e) => {
                changeKeywords(e.target.value);
              }}
            />
            <InputRightElement>
              <IconButton
                aria-label="delete text"
                isRound
                variant="link"
                onClick={() => {
                  changeKeywords('');
                }}
              >
                <IoCloseCircle />
              </IconButton>
            </InputRightElement>
          </InputGroup>
        </GridItem>
        <GridItem area="sort">
          <Suspense
            fallback={
              <Button variant="link" w="full">
                {messages.loading}
              </Button>
            }
          >
            <SortOptions />
          </Suspense>
        </GridItem>
        <GridItem area="filter">
          <Suspense
            fallback={
              <Button variant="link" w="full">
                {messages.loading}
              </Button>
            }
          >
            <SearchOptions />
          </Suspense>
        </GridItem>
      </Grid>
      <Section>
        <SectionHeading mb={0}>検索結果</SectionHeading>
        <SectionText mt={0} display={'flex'}>
          <Skeleton
            display="inline-block"
            isLoaded={Boolean(pagination || error)}
          >
            {pagination?.count ?? 0}
          </Skeleton>
          <Text>件中</Text>
          <Skeleton
            display="inline-block"
            isLoaded={Boolean(pagination || error)}
          >
            {error ? 0 : `1-${classes.length}`}
          </Skeleton>
          <Text>件目を表示</Text>
        </SectionText>
      </Section>
      <SimpleGrid as={SectionContents} gap={4} minChildWidth={'grid.md'}>
        {classes?.map((cls, index) => (
          <GridItem
            as={SearchClassCard}
            key={index}
            cls={cls}
            myClass={getMyClass(cls.id)}
            {...{ enroll, unenroll }}
          />
        ))}
      </SimpleGrid>
      {!error &&
        (pagination?.current_page ?? 0) < (pagination?.total_pages ?? 1) && (
          <Progress ref={ref} isIndeterminate />
        )}
      {error && (
        <VStack gap={4}>
          <Heading fontSize={'2xl'}>{error.message}</Heading>
          <Box overflow={'auto'} w={'full'} h={'100dvh'}>
            {getErrorToastDescription(error)}
          </Box>
        </VStack>
      )}
    </MainContainer>
  );
}
