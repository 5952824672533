import { Button, ButtonProps, Link, ThemeTypings } from '@chakra-ui/react';
import { ClassInfo, MyClassInfo } from 'app/hupassApi';
import { memo } from 'react';

export type ClassLinkButtonsId = 'moodle' | 'syllabus' | 'elms';

export type ClassLinkButtonsProps = {
  myClass?: MyClassInfo;
  cls?: ClassInfo;
  buttons?: ClassLinkButtonsId[];
} & ButtonProps;

export default memo(function ClassLinkButtons(props: ClassLinkButtonsProps) {
  const {
    myClass,
    cls = myClass?.cls,
    buttons = myClass ? ['moodle', 'syllabus', 'elms'] : ['syllabus'],
    ...buttonProps
  } = props;
  const links: Array<{
    id: ClassLinkButtonsId;
    label: React.ReactNode;
    link: string;
    color: ThemeTypings['colorSchemes'];
    isDisabled: boolean;
  }> = [
    {
      id: 'syllabus',
      label: 'シラバス',
      link: cls?.urls['syllabus'] ?? '',
      color: 'teal',
      isDisabled: !cls?.cls_id,
    },
    {
      id: 'moodle',
      label: 'Moodle',
      link: myClass?.c_moodle_url ?? cls?.urls['moodle'] ?? '',
      color: 'orange',
      isDisabled: !(myClass?.c_moodle_url ?? cls?.urls['moodle']),
    },
    {
      id: 'elms',
      label: 'ELMS',
      link: cls?.urls['elms'] ?? '',
      color: 'green',
      isDisabled: !cls?.cls_id,
    },
  ];

  return (
    <>
      {links.map(
        ({ id, label, link, color, isDisabled }, index) =>
          buttons.includes(id) &&
          (isDisabled ? (
            <Button
              key={index}
              isDisabled
              variant="outline"
              colorScheme={color}
              flex={1}
              {...buttonProps}
            >
              {label}
            </Button>
          ) : (
            <Button
              key={index}
              as={Link}
              href={link}
              variant="outline"
              colorScheme={color}
              flex={1}
              isExternal
              {...buttonProps}
            >
              {label}
            </Button>
          ))
      )}
    </>
  );
});
