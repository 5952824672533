import { ClassInfo, baseURL, type ClassPeriod } from 'app/hupassApi';

/**
 * 学期文字列を学期bitに変換
 * @param str 学期文字列
 * @return 学期bit
 */
export const StrToSemesterBit = (str: string | number): number => {
  switch (str) {
    case '１学期':
      return 0b0011;
    case '２学期':
      return 0b1100;
    case 1:
    case '１学期（春ターム）':
      return 0b0001;
    case 2:
    case '１学期（夏ターム）':
      return 0b0010;
    case 3:
    case '２学期（秋ターム）':
      return 0b0100;
    case 4:
    case '２学期（冬ターム）':
      return 0b1000;
    case '通年':
      return 0b1111;
    default:
      return 0b10000;
  }
};

export const StrToSemester = (str: string): number[] =>
  [1, 2, 3, 4].filter(
    (semester) => StrToSemesterBit(semester) & StrToSemesterBit(str)
  );

/**
 * 学期のインデックスを文字列に変換
 * @param semester 学期のインデックス [1-4]
 * @return 学期の文字列
 */
export const SemesterToStr = (semester: number): string =>
  [
    '１学期（春ターム）',
    '１学期（夏ターム）',
    '２学期（秋ターム）',
    '２学期（冬ターム）',
    '通年',
  ][semester - 1] || '';

export const days = ['月', '火', '水', '木', '金'];
export const periods = [
  ['8:45', '10:15'],
  ['10:30', '12:00'],
  ['13:00', '14:30'],
  ['14:45', '16:15'],
  ['16:30', '18:00'],
];

/**
 * 時限の文字列を曜日・時間のインデックスに変換
 * @param classPeriod 時限 月1 or 1-1
 * @returns 曜日・時間のインデックス 0から始まってエラー時には-1を返す
 */
export const StrToPeriod = (classPeriod: string): ClassPeriod => {
  const split = classPeriod.split('');
  let day = 0;
  let period = 0;
  if (split.length === 2) {
    day = days.indexOf(split[0]);
    period = Number(split[1]);
  } else if (split.length === 3) {
    day = Number(split[0]);
    period = Number(split[2]);
  }
  const inRange = (value: number, min: number, max: number) =>
    min <= value && value <= max;
  if (inRange(day, 1, 5) && inRange(period, 1, 5)) {
    return { day, period };
  }
  throw new Error(`${classPeriod}は有効な時限ではありません。`);
};

/**
 * 時限の曜日・時間のインデックスを文字列に変換
 * @param classPeriod 時限
 * @returns 曜日・時間の文字列
 */
export const PeriodToStr = (classPeriod: ClassPeriod, form = false): string => {
  if (classPeriod.day < 0 || classPeriod.period < 0) return '集中講義';
  if (form) return `${classPeriod.day}-${classPeriod.period}`;
  return days[classPeriod.day - 1] + classPeriod.period;
};

export const toHalfString = (text: string) =>
  text.replace(/[Ａ-Ｚａ-ｚ０-９]/g, (s) =>
    String.fromCharCode(s.charCodeAt(0) - 0xfee0)
  );

/**
 * @param grade スペース区切りの成績 ex. 'ap a am'
 */
export const getGradeRatio = (grade: string, cls?: ClassInfo) => {
  if (!cls?.grade_info) return undefined;
  const numOfAGradeStudents = Object.entries(cls.grade_info).reduce(
    (current, [key, value]) =>
      (grade.split(' ').includes(key) ? Number(value) : 0) + current,
    0
  );
  return Math.round((numOfAGradeStudents / cls.grade_info.numOfStudents) * 100);
};

export const getAGradeRatio = (cls?: ClassInfo) => {
  return getGradeRatio('ap a am', cls);
};

export const getPassRatio = (cls?: ClassInfo) => {
  return getGradeRatio('ap a am bp b bm cp c', cls);
};

export const getHighlightWords = (params: URLSearchParams) => {
  return params.get('keyword')?.split(' ') ?? [''];
};

export const reportError = (message: string) => {
  return fetch(`${baseURL}/client-log`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ text: message }),
  }).then((res) => res.json());
};
