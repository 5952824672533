import { FlatColors } from 'app/colors';
import hupassApi, { type MyClassInfo } from 'app/hupassApi';
import { StrToSemesterBit } from 'app/utils';
import ConfirmModal from 'components/common/modal/ConfirmModal';
import { errorHandler } from 'components/common/toast/toast';
import React, { useCallback, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { atom, useRecoilState } from 'recoil';
import { atomOptionsWithLocalStorage, localStorageKeys } from './localStorage';

// 旧データの移行
const oldCalendarData = localStorage.getItem('calendar');
if (oldCalendarData) {
  try {
    const oldData = JSON.parse(localStorage.getItem('calendar') ?? '') as {
      classes: Record<number, MyClassInfo>;
    };
    const myClasses = Object.values(oldData.classes).map((myClass) => ({
      ...myClass,
      c_place: myClass.c_place || myClass.cls.place || '',
      c_subject: myClass.c_subject || myClass.cls.subject || '',
      c_teacher: myClass.c_teacher || myClass.cls.teacher || '',
      c_theme: myClass.c_theme || myClass.cls.theme || '',
    }));
    localStorage.setItem(localStorageKeys.myClasses, JSON.stringify(myClasses));
    localStorage.setItem('old_data', localStorage.getItem('calendar') ?? '');
    localStorage.removeItem('calendar');
    localStorage.setItem(localStorageKeys.isLocalChange, 'true');
  } catch (e) {
    if (e instanceof Error) errorHandler(e);
  }
}

const myClassesState = atom(
  atomOptionsWithLocalStorage<MyClassInfo[]>(localStorageKeys.myClasses, [])
);

const isLocalChangedState = atom(
  atomOptionsWithLocalStorage(localStorageKeys.isLocalChange, false)
);

const isCookiesWithToken = (value: {
  token?: unknown;
}): value is { token: string } => {
  return (
    value.token !== undefined &&
    value.token !== null &&
    typeof value.token === 'string'
  );
};

const diffMyClasses = (
  localClasses: MyClassInfo[],
  serverClasses: MyClassInfo[]
) => {
  const localOnly: MyClassInfo[] = [];
  const serverOnly: MyClassInfo[] = [];
  const changed: { local: MyClassInfo; server: MyClassInfo }[] = [];
  localClasses.forEach((localClass) => {
    const serverClass = serverClasses.find(
      (myCls) => myCls.id === localClass.id
    );
    if (serverClass) {
      if (JSON.stringify(serverClass) !== JSON.stringify(localClass)) {
        changed.push({ local: localClass, server: serverClass });
      }
    } else {
      localOnly.push(localClass);
    }
  });
  serverClasses.forEach((serverClass) => {
    const localClass = localClasses.find(
      (myCls) => myCls.id === serverClass.id
    );
    if (!localClass) {
      serverOnly.push(serverClass);
    }
  });

  return { localOnly, serverOnly, changed };
};

const checkDuplicatedPeriod = (
  myClasses: MyClassInfo[],
  newClass: MyClassInfo
) => {
  return myClasses.every((myClass) => {
    if (myClass.cls.id === newClass.cls.id) return true;
    if (myClass.cls.year !== newClass.cls.year) return true;
    if (
      !(
        StrToSemesterBit(myClass.cls.semester) &
        StrToSemesterBit(newClass.cls.semester)
      )
    )
      return true;
    if (
      (myClass.c_periods.length
        ? myClass.c_periods
        : myClass.cls.periods
      ).every((currentP) =>
        (newClass.c_periods.length
          ? newClass.c_periods
          : newClass.cls.periods
        ).every(
          (newP) => currentP.day !== newP.day || currentP.period !== newP.period
        )
      )
    )
      return true;
    throw Error(
      `${myClass.c_subject ?? myClass.cls.subject}と時間が重複しています。`
    );
  });
};

const generateUpdateParams = (newMyClass: MyClassInfo) => {
  const { cls, ...data } = newMyClass;

  return {
    cls_id: cls.id,
    ...data,
  };
};

const getRandomColor = () => {
  return Object.values(FlatColors)[
    Math.floor(Object.keys(FlatColors).length * Math.random())
  ];
};

/**
 * 履修授業管理用hooks
 */
export const useMyClasses = ({
  setFetching,
}: {
  setFetching?: React.Dispatch<React.SetStateAction<boolean>>;
} = {}) => {
  const [myClasses, setMyClasses] = useRecoilState(myClassesState);
  const [isLocalChanged, setIsLocalChanged] =
    useRecoilState(isLocalChangedState);
  const [modal, setModal] = useState<React.ReactNode>();
  const [cookies] = useCookies(['token']);

  const fetchClasses = useCallback(
    async (
      props?: { ignore?: boolean; force?: boolean },
      controller?: AbortController
    ): Promise<boolean> => {
      if (!isCookiesWithToken(cookies)) return false;

      const getMyClasses = async (page: number = 1): Promise<MyClassInfo[]> => {
        const res = await hupassApi.ListMyClasses(
          { page },
          cookies.token,
          controller
        );
        if (!props?.ignore && res.data.current_page < res.data.total_pages) {
          return res.data.results.concat(await getMyClasses(page + 1));
        }
        return res.data.results;
      };

      setFetching?.(true);
      const serverClasses = await getMyClasses();

      return new Promise<boolean>((resolve) => {
        setMyClasses((localClasses) => {
          const diff = diffMyClasses(localClasses, serverClasses);

          if (Object.values(diff).flat().length === 0) {
            resolve(false);
            return localClasses;
          } else if (props?.force) {
            resolve(true);
            return serverClasses;
          }

          const toServer = () => {
            setMyClasses(serverClasses);
            resolve(true);
          };

          const toLocal = async () => {
            const tasks = [
              ...diff.serverOnly.map(async (myCls) => {
                await hupassApi.DeleteMyClass(myCls.cls.id, cookies.token);
              }),
              ...diff.changed.map(async ({ local: myCls }) => {
                await hupassApi.UpdateMyClass(
                  generateUpdateParams(myCls),
                  cookies.token
                );
              }),
              ...diff.localOnly.map(async (myCls) => {
                await hupassApi.CreateMyClass(
                  generateUpdateParams(myCls),
                  cookies.token
                );
              }),
            ];
            await Promise.all(tasks).catch(errorHandler);
            resolve(false);
          };

          if (serverClasses.length < 1) {
            toLocal().catch(errorHandler);
            return localClasses;
          }

          setModal(
            <ConfirmModal toServer={toServer} toLocal={() => void toLocal()} />
          );
          return localClasses;
        });
      }).then(
        (fetched) => {
          setFetching?.(false);
          setIsLocalChanged(false);
          return fetched;
        },
        (e) => {
          setFetching?.(false);
          throw e;
        }
      );
    },
    [cookies, setMyClasses, setIsLocalChanged, setFetching]
  );

  useEffect(() => {
    const props = { ignore: false, force: !isLocalChanged };
    const controller = new AbortController();

    fetchClasses(props, controller).catch(errorHandler);
    return () => {
      props.ignore = true;
      controller.abort();
    };
  }, [fetchClasses, isLocalChanged]);

  const getMyClass = useCallback(
    (class_id: number) =>
      myClasses.find((myClass) => myClass.cls.id === class_id),
    [myClasses]
  );

  const enroll = useCallback(
    async (cls_id: number) => {
      const addMyClass = (newMyClass: MyClassInfo) =>
        setMyClasses((currentMyClasses) => {
          checkDuplicatedPeriod(currentMyClasses, newMyClass);
          return currentMyClasses.concat(newMyClass);
        });
      if (!isCookiesWithToken(cookies)) {
        try {
          const { data } = await hupassApi.GetClass(cls_id);
          const newMyClass: MyClassInfo = {
            cls: data,
            color: getRandomColor(),
            memo: '',
            c_moodle_id: null,
            c_moodle_url: '',
            c_periods: data.periods,
            c_place: data.place,
            c_subject: data.subject,
            c_teacher: data.teacher,
            c_theme: data.theme,
          };
          addMyClass(newMyClass);
          setIsLocalChanged(true);
        } catch (e) {
          if (e instanceof Error) errorHandler(e);
        }
        return;
      }
      return hupassApi
        .CreateMyClass(
          {
            cls_id,
            color: getRandomColor(),
            memo: '',
            c_moodle_id: null,
            c_moodle_url: '',
            c_periods: [],
            c_place: '',
            c_subject: '',
            c_teacher: '',
            c_theme: '',
          },
          cookies.token
        )
        .then(async ({ data }) => {
          addMyClass(data);
          await fetchClasses();
        });
    },
    [cookies, fetchClasses, setMyClasses, setIsLocalChanged]
  );

  const enrollMultiple = useCallback(
    async (cls_ids: number[]) => {
      const addMyClass = (newMyClass: MyClassInfo) =>
        setMyClasses((currentMyClasses) => {
          return currentMyClasses.concat(newMyClass);
        });

      if (!isCookiesWithToken(cookies)) {
        for (const cls_id of cls_ids) {
          try {
            const { data } = await hupassApi.GetClass(cls_id);
            const newMyClass: MyClassInfo = {
              cls: data,
              color: getRandomColor(),
              memo: '',
              c_moodle_id: null,
              c_moodle_url: '',
              c_periods: data.periods,
              c_place: data.place,
              c_subject: data.subject,
              c_teacher: data.teacher,
              c_theme: data.theme,
            };
            addMyClass(newMyClass);
            setIsLocalChanged(true);
          } catch (e) {
            if (e instanceof Error) errorHandler(e);
          }
        }
        return;
      }

      const newMyClasses = [];
      for (const cls_id of cls_ids) {
        const newMyClass: Partial<Omit<MyClassInfo, 'cls'>> & {
          cls_id: number;
        } = {
          cls_id,
          color: getRandomColor(),
          memo: '',
          c_moodle_id: null,
          c_moodle_url: '',
          c_periods: [],
          c_place: '',
          c_subject: '',
          c_teacher: '',
          c_theme: '',
        };
        newMyClasses.push(newMyClass);
      }

      return hupassApi
        .CreateMultipleMyClasses(newMyClasses, cookies.token)
        .then(async ({ data }) => {
          data.forEach(addMyClass);
          await fetchClasses();
          if (data.length !== newMyClasses.length) {
            throw Error(`時間が重複している授業の登録はスキップされました。`);
          }
        });
    },
    [cookies, fetchClasses, setMyClasses, setIsLocalChanged]
  );

  const unenroll = useCallback(
    async (cls_id: number) => {
      const delMyClass = () =>
        setMyClasses((classes) =>
          classes.filter((myCls) => myCls.cls.id !== cls_id)
        );
      if (!isCookiesWithToken(cookies)) {
        delMyClass();
        setIsLocalChanged(true);
        return;
      }
      return hupassApi.DeleteMyClass(cls_id, cookies.token).then(async () => {
        delMyClass();
        await fetchClasses();
      });
    },
    [cookies, fetchClasses, setMyClasses, setIsLocalChanged]
  );

  const update = useCallback(
    async (newMyClass: MyClassInfo, controller?: AbortController) => {
      const updateLocalMyClass = (newClass: MyClassInfo, force = false) =>
        new Promise<void>((resolve, reject) => {
          setMyClasses((currentClasses) => {
            checkDuplicatedPeriod(currentClasses, newClass);
            return currentClasses.map((myCls) => {
              if (myCls.cls.id !== newClass.cls.id) return myCls;
              if (JSON.stringify(newClass) === JSON.stringify(myCls)) {
                resolve();
                return myCls;
              }
              if (force) {
                resolve();
                return newClass;
              }
              const toServer = () => {
                updateLocalMyClass(newClass, true).catch(errorHandler);
                reject(new Error('サーバーのデータで置換されました。'));
              };
              const toLocal = () => resolve();
              setModal(<ConfirmModal toServer={toServer} toLocal={toLocal} />);
              return myCls;
            });
          });
        });

      if (!isCookiesWithToken(cookies)) {
        return updateLocalMyClass(newMyClass, true).then(() => {
          setIsLocalChanged(true);
        });
      }
      return (
        hupassApi
          // サーバーから最新情報を取得し
          .GetMyClass(newMyClass.cls.id, cookies.token, controller)
          // ローカルと比較し（必要であればプロンプトを出す）
          .then(({ data }) => updateLocalMyClass(data))
          // サーバーを更新し
          .then(async () => {
            await updateLocalMyClass(newMyClass, true);
            return hupassApi.UpdateMyClass(
              generateUpdateParams(newMyClass),
              cookies.token,
              controller
            );
          })
          // 再度ローカルを最新に更新
          .then(async ({ data }) => {
            await updateLocalMyClass(data, true);
            await fetchClasses({ force: true });
          })
      );
    },
    [cookies, fetchClasses, setMyClasses, setIsLocalChanged]
  );

  return {
    modal,
    myClasses,
    getMyClass,
    fetchClasses,
    enroll,
    unenroll,
    enrollMultiple,
    update,
  };
};
