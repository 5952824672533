export const semanticTokens = {
  colors: {
    'chakra-body-sub-bg': { _light: 'gray.100', _dark: 'gray.900' },
    'chakra-body-bg-alpha': {
      _light: 'whiteAlpha.800',
      _dark: 'grayAlpha.800',
    },
    'chakra-link-color': { _light: 'cyan.600', _dark: 'cyan.200' },
    'chakra-highlight-color': { _light: 'orange.100', _dark: 'orange.100' },
    'chakra-primary-text': { _light: 'blue.600', _dark: 'blue.200' },
    'chakra-primary-bg': { _light: 'blue.50', _dark: 'blue.800' },
  },
};
