import { MyClassInfo } from 'app/hupassApi';
import Section from 'components/common/section/Section';
import { featureClassMemoState } from 'components/misc/Misc';
import { memo } from 'react';
import { useRecoilValue } from 'recoil';
import MarkdownMemo from './MarkdownMemo';
import TextMemo from './TextMemo';

export type MemoProps = {
  myClass: MyClassInfo;
  update: (
    newMyClass: MyClassInfo,
    controller?: AbortController
  ) => Promise<void>;
};

export default memo(function Memo(props: MemoProps) {
  const { myClass, update } = props;
  const featureClassMemo = useRecoilValue(featureClassMemoState);

  return (
    <Section>
      {
        {
          markdown: <MarkdownMemo myClass={myClass} update={update} />,
          text: <TextMemo myClass={myClass} update={update} />,
          'editor.js': '',
        }[featureClassMemo]
      }
    </Section>
  );
});
