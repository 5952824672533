import {
  Button,
  Card,
  CardBody,
  CardFooter,
  GridItem,
  HStack,
  Heading,
  Icon,
  Link,
  SimpleGrid,
  Text,
  ThemeTypings,
  useColorModeValue,
} from '@chakra-ui/react';
import { useTitle } from 'app/hooks/title';
import MainContainer from 'components/common/container/MainContainer';
import Section from 'components/common/section/Section';
import SectionContents from 'components/common/section/SectionContents';
import SectionHeading from 'components/common/section/SectionHeading';
import SectionStack from 'components/common/section/SectionStack';
import { memo } from 'react';
import { FcHighPriority } from 'react-icons/fc';
import { IoChevronForward } from 'react-icons/io5';

const Home = memo(function Home() {
  useTitle('ホーム');
  const usefulSites: {
    name: React.ReactNode;
    link: string;
    description: React.ReactNode[];
    colorScheme?: ThemeTypings['colorSchemes'];
  }[] = [
    {
      name: 'Hupass意見箱',
      link: 'https://forms.gle/xPbcCHt6dnB4Scu29',
      description: [
        'Hupassに関する、意見・要望・問い合わせを送ることができます。',
      ],
      colorScheme: 'orange',
    },
    {
      name: 'iNAZO',
      link: 'https://inazo.hu-jagajaga.com',
      description: ['Hupassが連携している成績データを見ることができます。'],
      colorScheme: 'blue',
    },
    {
      name: '全学補講・休講情報',
      link: 'https://inform.academic.hokudai.ac.jp/webinfo/p/SerchInfo.aspx',
      description: ['全学教育の休講情報が掲載されています。'],
      colorScheme: 'green',
    },
    {
      name: '工学部講義情報',
      link: 'https://www.eng.hokudai.ac.jp/lecinfo/',
      description: ['工学部の講義情報が掲載されています。'],
      colorScheme: 'yellow',
    },
    {
      name: '法学部講義情報',
      link: 'https://www.cam-life.net/kyukou/pc/nolec_view.php?sid=2',
      description: ['法学部の休講情報が掲載されています。'],
      colorScheme: 'teal',
    },
  ];
  const light = useColorModeValue('400', '500');
  const dark = useColorModeValue('600', '700');

  return (
    <MainContainer>
      <SectionStack>
        <Section>
          <SectionHeading>お知らせ</SectionHeading>
          <SectionContents>
            <Card variant={'filled'}>
              <CardBody>
                <HStack gap={4}>
                  <Icon as={FcHighPriority} fontSize={'4xl'} />
                  <Text>工事中…</Text>
                </HStack>
              </CardBody>
            </Card>
          </SectionContents>
        </Section>
        <Section>
          <SectionContents mt={0}>
            <SimpleGrid minChildWidth={'grid.sm'} gap={4}>
              {usefulSites.map(
                ({ name, link, description, colorScheme = 'blue' }, i) => {
                  return (
                    <GridItem key={i}>
                      <Card
                        bgGradient={`linear(to-b, ${colorScheme}.${light}, ${colorScheme}.${dark})`}
                        color={'whitesmoke'}
                        rounded={'2xl'}
                        h={'full'}
                      >
                        <CardBody>
                          <Heading fontSize={'xl'} mb={4}>
                            {name}
                          </Heading>
                          {description.map((el, i2) => {
                            return typeof el === 'string' ? (
                              <Text key={i2} mb={2}>
                                {el}
                              </Text>
                            ) : (
                              el
                            );
                          })}
                        </CardBody>
                        <CardFooter pt={0}>
                          <Button
                            w={'full'}
                            as={Link}
                            href={link}
                            bgColor={'whiteAlpha.200'}
                            color={'white'}
                            _hover={{
                              bgColor: 'whiteAlpha.300',
                            }}
                            _active={{
                              bgColor: 'whiteAlpha.400',
                            }}
                            rightIcon={<IoChevronForward />}
                            isExternal
                          >
                            アクセスする
                          </Button>
                        </CardFooter>
                      </Card>
                    </GridItem>
                  );
                }
              )}
            </SimpleGrid>
          </SectionContents>
        </Section>
      </SectionStack>
    </MainContainer>
  );
});

export default Home;
