import {
  Box,
  Center,
  ThemeTypings,
  UseRadioProps,
  useColorModeValue,
  useRadio,
} from '@chakra-ui/react';
import { PropsWithChildren, memo } from 'react';

export default memo(function SemesterRadioButton(
  props: PropsWithChildren<UseRadioProps> & {
    colorScheme: ThemeTypings['colorSchemes'];
  }
) {
  const { getInputProps, getRadioProps } = useRadio(props);
  const color = useColorModeValue(
    `${props.colorScheme}.500`,
    `${props.colorScheme}.300`
  );

  return (
    <Box as="label" flexGrow={1}>
      <input {...getInputProps()} />
      <Center
        {...getRadioProps()}
        p={2}
        border={'1px'}
        borderColor={color}
        borderRadius={'md'}
        color={color}
        fontWeight={'bold'}
        _checked={{ bgColor: color, color: 'chakra-inverse-text' }}
      >
        {props.children}
      </Center>
    </Box>
  );
});
