import {
  Button,
  Checkbox,
  CheckboxGroup,
  CheckboxGroupProps,
  Flex,
  Grid,
  GridItem,
} from '@chakra-ui/react';
import { SearchOption } from 'app/hupassApi';
import { memo } from 'react';

export type PeriodFormProps = {
  choices: SearchOption['choices'];
} & CheckboxGroupProps;

export default memo(function PeriodForm(props: PeriodFormProps) {
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { value, onChange, choices } = props;
  const handleClickDay = (day: number) => () => {
    if (
      Number(
        value?.filter(
          (formPeriod) =>
            String(formPeriod).substring(0, 1) === (day + 1).toString()
        )?.length
      ) > 4
    ) {
      onChange?.(
        value?.filter(
          (formPeriod) =>
            String(formPeriod).substring(0, 1) !== (day + 1).toString()
        ) ?? []
      );
    } else {
      onChange?.([
        ...(value ?? []),
        ...choices.flatMap(([classPeriod]) =>
          String(classPeriod).substring(0, 1) === (day + 1).toString() &&
          !value?.includes(classPeriod)
            ? classPeriod
            : []
        ),
      ]);
    }
  };

  const handleClickPeriod = (period: number) => () => {
    if (
      Number(
        value?.filter(
          (classPeriod) =>
            String(classPeriod).substring(2, 3) === (period + 1).toString()
        ).length
      ) > 5
    ) {
      onChange?.(
        value?.filter(
          (classPeriod) =>
            String(classPeriod).substring(2, 3) !== (period + 1).toString()
        ) ?? []
      );
    } else {
      onChange?.([
        ...(value ?? []),
        ...choices.flatMap(([classPeriod]) =>
          String(classPeriod).substring(2, 3) === (period + 1).toString() &&
          !value?.includes(classPeriod)
            ? classPeriod
            : []
        ),
      ]);
    }
  };

  return (
    <CheckboxGroup size={{ base: 'md', md: 'lg' }} {...props}>
      <Grid
        rowGap={2}
        columnGap={2}
        templateColumns={'auto repeat(1fr, 5)'}
        templateRows={'auto repeat(1fr, 5)'}
        w="full"
      >
        {[
          <Flex key="label" alignItems="flex-end" justifyContent="center">
            時限
          </Flex>,
          ['月', '火', '水', '木', '金'].map((weekday, day) => (
            <GridItem
              key={weekday}
              rowStart={1}
              colStart={day + 2}
              as={Flex}
              justifyContent="center"
            >
              <Button onClick={handleClickDay(day)} w={'full'}>
                {weekday}
              </Button>
            </GridItem>
          )),
          [1, 2, 3, 4, 5].map((time, period) => (
            <GridItem
              key={time}
              rowStart={period + 2}
              colStart={1}
              as={Flex}
              justifyContent="center"
            >
              <Button onClick={handleClickPeriod(period)} w={'full'}>
                {time}
              </Button>
            </GridItem>
          )),
        ]}
        {choices.map(([val]) => (
          <Flex key={val} justifyContent={'center'}>
            <Checkbox
              value={String(val)}
              w={'full'}
              h={10}
              sx={{
                '.chakra-checkbox__control': {
                  w: 'full',
                  h: 'full',
                  rounded: 'md',
                },
              }}
            />
          </Flex>
        ))}
      </Grid>
    </CheckboxGroup>
  );
});
