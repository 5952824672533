import {
  Button,
  Center,
  CircularProgress,
  Divider,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  HStack,
  Heading,
  IconButton,
  Switch,
  Text,
  VStack,
  useDisclosure,
} from '@chakra-ui/react';
import { appOptionsState, defaultAppOptions } from 'App';
import { thisSemester, thisYear } from 'app/date';
import {
  atomOptionsWithLocalStorage,
  localStorageKeys,
  useJsonLocalStorage,
} from 'app/hooks/localStorage';
import { useMyClasses } from 'app/hooks/myClasses';
import { useTitle } from 'app/hooks/title';
import {
  SemesterToStr,
  StrToSemesterBit,
  periods,
  toHalfString,
} from 'app/utils';
import MainContainer from 'components/common/container/MainContainer';
import BottomDrawer from 'components/common/drawer/BottomDrawer';
import BottomDrawerBody from 'components/common/drawer/BottomDrawerBody';
import BottomDrawerHeader from 'components/common/drawer/BottomDrawerHeader';
import { errorHandler } from 'components/common/toast/toast';
import { memo, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import {
  IoCalendarClearOutline,
  IoChevronDown,
  IoChevronDownCircle,
  IoRefresh,
} from 'react-icons/io5';
import { atom, useRecoilState, useSetRecoilState } from 'recoil';
import EmptyClassCard from './card/EmptyClassCard';
import MyClassCard from './card/MyClassCard';
import TimetableClassCard from './card/TimetableClassCard';
import SemesterSelector from './selector/SemesterSelector';
import YearSelector from './selector/YearSelector';

const initialIndexTable = (): [
  [number | null, number | null, number | null, number | null, number | null],
  [number | null, number | null, number | null, number | null, number | null],
  [number | null, number | null, number | null, number | null, number | null],
  [number | null, number | null, number | null, number | null, number | null],
  [number | null, number | null, number | null, number | null, number | null],
] => [
  [null, null, null, null, null],
  [null, null, null, null, null],
  [null, null, null, null, null],
  [null, null, null, null, null],
  [null, null, null, null, null],
];

const showPeriodsState = atom(
  atomOptionsWithLocalStorage(localStorageKeys.showPeriods, true)
);

export default memo(function Timetable() {
  useTitle('時間割');
  const setAppOptions = useSetRecoilState(appOptionsState);
  useEffect(() => {
    setAppOptions({ header: true, footer: false, navigation: true });

    return () => {
      setAppOptions(defaultAppOptions);
    };
  }, [setAppOptions]);
  const [fetching, setFetching] = useState(false);

  const { myClasses, modal, fetchClasses } = useMyClasses({ setFetching });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [cookies] = useCookies(['token']);
  const [year, setYear] = useJsonLocalStorage(
    localStorageKeys.timetableYear,
    thisYear
  );
  const [semester, setSemester] = useJsonLocalStorage(
    localStorageKeys.timetableSemester,
    thisSemester
  );
  const [today, setToday] = useState(new Date());
  const [showPeriods, setShowPeriods] = useRecoilState(showPeriodsState);

  useEffect(() => {
    setTimeout(
      () => {
        setToday(new Date());
      },
      (60 - new Date().getSeconds()) * 1000
    );
  }, [today]);

  const indexTable = initialIndexTable();
  const intensives = [];

  myClasses.forEach((myClass, index) => {
    const clsInfo = myClass.cls;
    if (
      clsInfo.year === year() &&
      StrToSemesterBit(clsInfo.semester) & (1 << (semester() - 1))
    )
      (myClass.c_periods.length ? myClass.c_periods : clsInfo.periods).forEach(
        ({ period, day }) => {
          if (period < 1 || day < 1) {
            intensives.push(index);
          } else {
            indexTable[day - 1][period - 1] = index;
          }
        }
      );
  });

  let consecutive = 1;
  const currentTime = today.getHours() * 100 + today.getMinutes();

  return (
    <Grid gridTemplateColumns={'1fr'} gridTemplateRows={'1fr'} flex={1} py={2}>
      {modal}
      <MainContainer display="flex" flexDirection="column" gap={4}>
        <HStack justifyContent={'space-between'}>
          <Button
            leftIcon={<IoCalendarClearOutline />}
            rightIcon={<IoChevronDown />}
            onClick={onOpen}
            variant="link"
          >
            {year()}年度 {toHalfString(SemesterToStr(semester()))}
          </Button>
          <BottomDrawer isOpen={isOpen} onClose={onClose}>
            <BottomDrawerHeader onClose={onClose}>
              表示オプション
            </BottomDrawerHeader>
            <Divider />
            <BottomDrawerBody my={4}>
              <VStack alignItems="stretch" gap={4}>
                <FormControl display={'flex'}>
                  <FormLabel flex={1}>時間を表示する</FormLabel>
                  <Switch
                    isChecked={showPeriods}
                    onChange={(e) => setShowPeriods(e.target.checked)}
                  />
                </FormControl>
                <YearSelector
                  value={String(year())}
                  onChange={(value) => {
                    setYear(Number(value));
                  }}
                />
                <SemesterSelector
                  value={String(semester())}
                  onChange={(value) => {
                    setSemester(Number(value));
                  }}
                />
              </VStack>
            </BottomDrawerBody>
          </BottomDrawer>
          {cookies.token &&
            (fetching ? (
              <Center w={10}>
                <CircularProgress isIndeterminate size={'16px'} />
              </Center>
            ) : (
              <IconButton
                aria-label="refresh"
                onClick={() => void fetchClasses().catch(errorHandler)}
                isDisabled={fetching}
                isRound
                variant={'link'}
              >
                <IoRefresh />
              </IconButton>
            ))}
        </HStack>
        <Grid
          flex={1}
          templateRows="auto repeat(5, 1fr)"
          templateColumns={'auto 1fr'}
          rowGap={1}
        >
          {showPeriods &&
            periods.map(([start, end], i) => {
              const isCurrentTime =
                Number(start.replace(':', '')) <= currentTime &&
                currentTime <= Number(end.replace(':', ''));

              return (
                <GridItem
                  key={i}
                  colStart={1}
                  rowStart={i + 2}
                  mr={{ base: 1.5, md: 4 }}
                >
                  <VStack
                    justifyContent={'space-around'}
                    h={'full'}
                    fontSize={{ base: 'xs', md: 'sm', lg: 'md' }}
                    color={
                      isCurrentTime
                        ? 'chakra-primary-text'
                        : 'chakra-subtle-text'
                    }
                    fontWeight={isCurrentTime ? 'bold' : 'normal'}
                  >
                    <Text>{start}</Text>
                    {isCurrentTime ? (
                      <IoChevronDownCircle />
                    ) : (
                      <IoChevronDown />
                    )}
                    <Text>{end}</Text>
                  </VStack>
                </GridItem>
              );
            })}
          <Grid
            templateColumns="repeat(5, 1fr)"
            templateRows={'subgrid'}
            templateAreas={`
            "mon tue wed thu fri"
          `}
            gridRow={'1 / -1'}
            gridColumn={'2 / -1'}
            gap={1}
            w="full"
          >
            {[
              ['月', 'mon'],
              ['火', 'tue'],
              ['水', 'wed'],
              ['木', 'thu'],
              ['金', 'fri'],
            ].map(([weekday, area], i) => {
              const isCurrentDay = today.getDay() === i + 1;

              return (
                <GridItem key={i} area={area}>
                  <TimetableClassCard
                    variant={'filled'}
                    bgColor={
                      isCurrentDay ? 'chakra-primary-text' : 'chakra-subtle-bg'
                    }
                    color={
                      isCurrentDay ? 'chakra-inverse-text' : 'chakra-body-text'
                    }
                  >
                    <Heading
                      fontSize={{ base: 'sm', md: 'md' }}
                      p={2}
                      textAlign="center"
                    >
                      {weekday}
                    </Heading>
                  </TimetableClassCard>
                </GridItem>
              );
            })}
            {indexTable.flatMap((indexes, day) =>
              indexes.flatMap((index, period) => {
                const per = `${day + 1}-${period - consecutive + 2}`;
                const gridColumn = `${day + 1}`;
                const gridRow = `${
                  period - consecutive + 3
                } / span ${consecutive}`;
                if (index === null)
                  return (
                    <GridItem
                      key={per}
                      gridColumn={gridColumn}
                      gridRow={gridRow}
                      minWidth={0}
                    >
                      <EmptyClassCard
                        year={year()}
                        semester={SemesterToStr(semester())}
                        period={per}
                      />
                    </GridItem>
                  );
                if (period < 5 && indexTable[day][period + 1] === index) {
                  consecutive++;
                  return [];
                }
                consecutive = 1;
                return (
                  <GridItem
                    key={per}
                    gridColumn={gridColumn}
                    gridRow={gridRow}
                    minWidth={0}
                  >
                    <MyClassCard
                      myClass={myClasses[index]}
                      year={year()}
                      semester={SemesterToStr(semester())}
                      period={per}
                    />
                  </GridItem>
                );
              })
            )}
          </Grid>
        </Grid>
      </MainContainer>
    </Grid>
  );
});
