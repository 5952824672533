import { theme } from '@chakra-ui/react';
import { transparentize } from '@chakra-ui/theme-tools';

const colors = {
  grayAlpha: {
    50: transparentize('gray.800', 0.04)(theme),
    100: transparentize('gray.800', 0.06)(theme),
    200: transparentize('gray.800', 0.08)(theme),
    300: transparentize('gray.800', 0.16)(theme),
    400: transparentize('gray.800', 0.24)(theme),
    500: transparentize('gray.800', 0.36)(theme),
    600: transparentize('gray.800', 0.48)(theme),
    700: transparentize('gray.800', 0.64)(theme),
    800: transparentize('gray.800', 0.8)(theme),
    900: transparentize('gray.800', 0.92)(theme),
  },
};

export default colors;
