import {
  Box,
  Button,
  CardBody,
  Grid,
  GridItem,
  Heading,
  Link,
  Text,
  HStack,
} from '@chakra-ui/react';
import { FlatColors } from 'app/colors';
import { ClassInfo, MyClassInfo } from 'app/hupassApi';
import { ClassCard } from 'components/common/card/ClassCard';
import Section from 'components/common/section/Section';
import SectionContents from 'components/common/section/SectionContents';
import SectionHeading from 'components/common/section/SectionHeading';
import { memo } from 'react';
import { IoSearch, IoOpenOutline } from 'react-icons/io5';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts';

export type GradeGraphProps = {
  myClass?: MyClassInfo;
  cls?: ClassInfo;
};

export default memo(function GradeGraph(props: GradeGraphProps) {
  const { myClass, cls = myClass?.cls } = props;

  const colors = {
    A: '#2196f3',
    B: '#bbdefb',
    C: '#ff9800',
    D: '#f44336',
    F: '#f44336',
  };

  const chartData = [
    {
      name: '+',
      value: cls?.grade_info?.ap,
      color: colors.A,
    },
    {
      name: 'A',
      value: cls?.grade_info?.a,
      color: colors.A,
    },
    {
      name: '-',
      value: cls?.grade_info?.am,
      color: colors.A,
    },
    {
      name: '+',
      value: cls?.grade_info?.bp,
      color: colors.B,
    },
    {
      name: 'B',
      value: cls?.grade_info?.b,
      color: colors.B,
    },
    {
      name: '-',
      value: cls?.grade_info?.bm,
      color: colors.B,
    },
    {
      name: '+',
      value: cls?.grade_info?.cp,
      color: colors.C,
    },
    {
      name: 'C',
      value: cls?.grade_info?.c,
      color: colors.C,
    },
    {
      name: 'D',
      value: cls?.grade_info?.d,
      color: colors.D,
    },
    {
      name: '-',
      value: cls?.grade_info?.dm,
      color: colors.D,
    },
    {
      name: 'F',
      value: cls?.grade_info?.f,
      color: colors.F,
    },
  ];

  return cls?.grade_info ? (
    <Section>
      <SectionHeading>成績情報</SectionHeading>
      <SectionContents>
        <ClassCard classColor={myClass?.color ?? FlatColors.silver}>
          <CardBody>
            <Box mb={4}>
              <Text color={'chakra-subtle-text'} fontWeight={'bold'}>
                {cls.grade_info.year}年度 {cls.grade_info.semester}{' '}
                {cls.grade_info.faculty}
              </Text>
            </Box>
            <Box mb={4}>
              <Heading size={'lg'}>{cls.grade_info.subject}</Heading>
              <Text color={'chakra-subtle-text'}>
                {cls.grade_info.lecture} {cls.grade_info.group}
              </Text>
            </Box>
            <Grid
              mb={8}
              templateColumns={'auto 1fr'}
              columnGap={4}
              rowGap={1}
              alignItems={'center'}
            >
              <GridItem
                color={'chakra-subtle-text'}
                fontSize={'small'}
                fontWeight={'bold'}
              >
                担当教員名
              </GridItem>
              <GridItem>{cls.grade_info.teacher}</GridItem>
              <GridItem
                color={'chakra-subtle-text'}
                fontSize={'small'}
                fontWeight={'bold'}
              >
                履修人数
              </GridItem>
              <GridItem>{cls.grade_info.numOfStudents} 人</GridItem>
              <GridItem
                color={'chakra-subtle-text'}
                fontSize={'small'}
                fontWeight={'bold'}
              >
                GPA
              </GridItem>
              <GridItem>{cls.grade_info.gpa}</GridItem>
            </Grid>
            <Box
              sx={{
                '& .recharts-text': {
                  fill: 'chakra-subtle-text',
                  fontWeight: 'bold',
                },
                '& .recharts-cartesian-grid line': {
                  stroke: 'chakra-border-color',
                },
              }}
            >
              <ResponsiveContainer height={300}>
                <BarChart data={chartData}>
                  <XAxis dataKey={'name'} axisLine={false} tickLine={false} />
                  <YAxis axisLine={false} tickLine={false} width={30} />
                  <CartesianGrid vertical={false} />
                  <Bar dataKey="value" name="人数" radius={4}>
                    {chartData.map((item, i) => (
                      <Cell fill={item.color} key={i} />
                    ))}
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </Box>
          </CardBody>
        </ClassCard>
      </SectionContents>
      <SectionContents>
        <HStack wrap={'wrap'}>
          <Button
            as={Link}
            rightIcon={<IoOpenOutline />}
            w={'full'}
            colorScheme="cyan"
            variant="outline"
            href={`https://inazo.hu-jagajaga.com/detail/${cls.grade_info.id}`}
            isExternal
          >
            iNAZOで見る
          </Button>
        </HStack>
      </SectionContents>
    </Section>
  ) : (
    <Section>
      <SectionHeading
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
        mb={0}
      >
        成績情報がみつかりません
        <Button
          as={Link}
          leftIcon={<IoSearch />}
          href={`https://inazo.hu-jagajaga.com/search?search=${cls?.subject}+${cls?.theme}+${cls?.teacher.replace(
            /\(.*\)/,
            ''
          )}`}
          isExternal
        >
          iNAZOで検索
        </Button>
      </SectionHeading>
    </Section>
  );
});
