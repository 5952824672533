import { Box, Card, type CardProps } from '@chakra-ui/react';
import { memo } from 'react';

export type ClassCardProps = CardProps & {
  classColor?: string;
  isBgColor?: boolean;
};

export const ClassCard = memo(function ClassCard(props: ClassCardProps) {
  const { classColor, children, isBgColor = false, ...rest } = props;
  return (
    <Card
      position={'relative'}
      h="full"
      pl={'3px'}
      {...(isBgColor &&
        classColor && {
          bgColor: `${classColor}1a`,
        })}
      {...rest}
    >
      <Box
        pos={'absolute'}
        overflow={'hidden'}
        rounded={'inherit'}
        top={0}
        left={0}
        right={0}
        bottom={0}
        pointerEvents={'none'}
        userSelect={'none'}
      >
        {classColor && <Box width="3px" h={'full'} bgColor={classColor} />}
      </Box>
      {children}
    </Card>
  );
});
