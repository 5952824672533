import { DrawerHeader, HStack, Heading, IconButton } from '@chakra-ui/react';
import React, { memo } from 'react';
import { IoClose } from 'react-icons/io5';

export interface BottomDrawerHeaderProps {
  children?: React.ReactNode;
  onClose?: React.MouseEventHandler<HTMLButtonElement>;
}

const BottomDrawerHeader = memo(function BottomDrawerHeader(
  props: BottomDrawerHeaderProps
) {
  const { children, onClose } = props;
  return (
    <DrawerHeader pt={6}>
      <HStack justifyContent="space-between">
        <Heading as="h4" fontSize="xl">
          {children}
        </Heading>
        <IconButton
          aria-label="close"
          onClick={onClose}
          fontSize="lg"
          variant="ghost"
        >
          <IoClose />
        </IconButton>
      </HStack>
    </DrawerHeader>
  );
});

export default BottomDrawerHeader;
