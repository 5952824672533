import { thisYear } from 'app/date';
import { type SecondLanguage } from 'app/hupassApi';
import { atom } from 'recoil';

export const yearState = atom<string>({
  key: 'yearState',
  default: thisYear.toString(),
});

export const departmentState = atom<string>({
  key: 'departmentState',
  default: '',
});

export const baseClassState = atom<string>({
  key: 'baseClassState',
  default: '',
});

export const secondLanguageState = atom<SecondLanguage>({
  key: 'secondLanguageState',
  default: null,
});

export const secondLanguageSubclassState = atom<string>({
  key: 'secondLanguageSubclassState',
  default: '',
});

export const secondLanguageClassState = atom<string>({
  key: 'secondLanguageClassState',
  default: '',
});

export const eng1ClassState = atom<string>({
  key: 'eng1ClassState',
  default: '',
});

export const eng2ClassState = atom<string>({
  key: 'eng2ClassState',
  default: '',
});

export const studentIdLast2State = atom<string>({
  key: 'studentIdLast2State',
  default: '',
});

export const checkboxValuesState = atom<{
  ph: boolean;
  ch: boolean;
  bi: boolean;
  ge: boolean;
}>({
  key: 'checkboxValuesState',
  default: {
    ph: false,
    ch: false,
    bi: false,
    ge: false,
  },
});
