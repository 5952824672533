const typography = {
  fonts: {
    // heading: `BlinkMacSystemFont, 'Avenir Next', 'Segoe UI', -apple-system, 'Hiragino Kaku Gothic ProN', 'Yu Gothic', Meiryo, sans-serif`,
    // body: `BlinkMacSystemFont, 'Avenir Next', 'Segoe UI', -apple-system, 'Hiragino Kaku Gothic ProN', 'Yu Gothic', Meiryo, sans-serif`,
    heading:
      '"Helvetica Neue", "Helvetica", "Hiragino Sans", "Hiragino Kaku Gothic ProN", "Arial", "Yu Gothic", "Meiryo", sans-serif',
    body: '"Helvetica Neue", "Helvetica", "Hiragino Sans", "Hiragino Kaku Gothic ProN", "Arial", "Yu Gothic", "Meiryo", sans-serif',
    mono: `SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New","Hiragino Sans", "Hiragino Kaku Gothic ProN", "Arial", "Yu Gothic", "Meiryo",monospace`,
  },
};

export default typography;
