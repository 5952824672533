import {
  Badge,
  Card,
  CardBody,
  Center,
  Grid,
  GridItem,
  HStack,
  Heading,
  Icon,
  Text,
  VStack,
} from '@chakra-ui/react';
import { thisSemester, thisYear } from 'app/date';
import { useMyClasses } from 'app/hooks/myClasses';
import { MyClassInfo } from 'app/hupassApi';
import { StrToSemesterBit, periods } from 'app/utils';
import MainContainer from 'components/common/container/MainContainer';
import Section from 'components/common/section/Section';
import SectionContents from 'components/common/section/SectionContents';
import SectionHeading from 'components/common/section/SectionHeading';
import SectionStack from 'components/common/section/SectionStack';
import { memo, useEffect, useMemo, useState } from 'react';
import { IoAlertCircle, IoArrowDown } from 'react-icons/io5';
import TimelineClassCard from './card/TimelineClassCard';
import TimelineEmptyCard from './card/TimelineEmptyCard';
import { useTitle } from 'app/hooks/title';

const Timeline = memo(function Timeline() {
  useTitle('今日の授業');
  const { myClasses } = useMyClasses();
  const [today, setToday] = useState(new Date());
  const weekday = today.getDay();

  const todayClases = useMemo(() => {
    const classList: [
      MyClassInfo | null,
      MyClassInfo | null,
      MyClassInfo | null,
      MyClassInfo | null,
      MyClassInfo | null,
    ] = [null, null, null, null, null];
    const addClassToIndex = (myClass: MyClassInfo) => {
      const matchYear = myClass.cls.year === thisYear;
      const matchSemester =
        StrToSemesterBit(myClass.cls.semester) & (1 << (thisSemester - 1));
      if (!matchYear || !matchSemester) return;
      (myClass.c_periods.length
        ? myClass.c_periods
        : myClass.cls.periods
      ).forEach(({ day, period }) => {
        if (day !== weekday) return;
        classList[period - 1] = myClass;
      });
    };
    myClasses.forEach(addClassToIndex);

    return classList;
  }, [myClasses, weekday]);

  useEffect(() => {
    setTimeout(
      () => {
        setToday(new Date());
      },
      (60 - new Date().getSeconds()) * 1000
    );
  }, [today]);

  const getMinutes = (time: string) => {
    const hm = time.split(':');
    return Number(hm[0]) * 60 + Number(hm[1]);
  };
  const untilEnd = periods.flatMap(([start, end]) => {
    const currentTime = getMinutes(today.getHours() + ':' + today.getMinutes());
    if (getMinutes(start) <= currentTime && currentTime <= getMinutes(end)) {
      return getMinutes(end) - currentTime;
    }
    return [];
  })[0];
  const untilStart = periods.reduce(
    (current, [start]) => {
      const currentTime = getMinutes(
        today.getHours() + ':' + today.getMinutes()
      );
      if (getMinutes(start) - currentTime <= 0) {
        return current;
      }
      return Math.min(current, getMinutes(start) - currentTime);
    },
    24 * 60 * 60
  );

  let consecutive = 1;

  return (
    <MainContainer>
      <SectionStack>
        <Section>
          <Card variant={'filled'}>
            <CardBody>
              <HStack>
                <Icon as={IoAlertCircle} />
                <Text>このページは現在ベータ版です。</Text>
              </HStack>
            </CardBody>
          </Card>
        </Section>
        <Section>
          <SectionHeading>今日は</SectionHeading>
          <SectionContents>
            <Center alignItems={'baseline'} gap={2}>
              <Heading
                fontSize={{ base: '5xl', md: '6xl', lg: '8xl' }}
                color={'chakra-primary-text'}
              >
                {today.getMonth() + 1}
              </Heading>
              <Text
                fontSize={{ base: '2xl', md: '3xl', lg: '4xl' }}
                color={'chakra-subtle-text'}
                mb={2}
              >
                月
              </Text>
              <Heading
                fontSize={{ base: '5xl', md: '6xl', lg: '8xl' }}
                color={'chakra-primary-text'}
              >
                {today.getDate()}
              </Heading>
              <Text
                fontSize={{ base: '2xl', md: '3xl', lg: '4xl' }}
                color={'chakra-subtle-text'}
                mb={2}
              >
                日
              </Text>
              <Heading
                fontSize={{ base: '4xl', md: '5xl', lg: '7xl' }}
                ml={2}
                color={'chakra-primary-text'}
              >
                {['日', '月', '火', '水', '木', '金', '土'][today.getDay()] +
                  '曜日'}
              </Heading>
            </Center>
          </SectionContents>
        </Section>
        {untilEnd > 0 && (
          <Section>
            <SectionHeading>今の授業が終わるのは</SectionHeading>
            <SectionContents>
              <Center>
                <Badge
                  px={8}
                  rounded={'2xl'}
                  colorScheme={
                    untilEnd < 30 ? 'green' : untilEnd < 60 ? 'orange' : 'red'
                  }
                >
                  <Text fontSize={{ base: '6xl', md: '7xl', lg: '8xl' }}>
                    {untilEnd}分後
                  </Text>
                </Badge>
              </Center>
            </SectionContents>
          </Section>
        )}
        {untilStart <= 15 && (
          <Section>
            <SectionHeading>次の授業が始まるのは</SectionHeading>
            <SectionContents>
              <Center>
                <Badge
                  px={8}
                  rounded={'2xl'}
                  colorScheme={
                    untilStart > 10
                      ? 'green'
                      : untilStart > 5
                        ? 'orange'
                        : 'red'
                  }
                >
                  <Text fontSize={{ base: '6xl', md: '7xl', lg: '8xl' }}>
                    {untilStart}分後
                  </Text>
                </Badge>
              </Center>
            </SectionContents>
          </Section>
        )}
        <Section>
          <SectionHeading>今日の授業</SectionHeading>
          <SectionContents
            display={'grid'}
            gridTemplateColumns={'auto 1fr'}
            columnGap={{ base: 1, md: 2 }}
            rowGap={4}
          >
            {todayClases.map((myClass, i) => {
              if (myClass !== todayClases[i - 1]) {
                consecutive = 1;
              }
              if (myClass === todayClases[i + 1]) {
                consecutive++;
                return null;
              }
              const startTime = periods[i - consecutive + 1][0];
              const endTime = periods[i][1];
              const gridRowStart = i - consecutive + 2;
              const now = today.getHours() * 100 + today.getMinutes();
              const isCurrentTime =
                Number(startTime.replace(':', '')) <= now &&
                now <= Number(endTime.replace(':', ''));
              return (
                <GridItem
                  as={Grid}
                  templateColumns={'subgrid'}
                  colSpan={2}
                  rowStart={gridRowStart}
                  rowSpan={consecutive}
                  key={i}
                  bgColor={isCurrentTime ? 'chakra-primary-bg' : 'transparent'}
                  rounded={'md'}
                >
                  <GridItem
                    key={i}
                    colStart={1}
                    px={{ base: 2, md: 4 }}
                    py={2}
                    color={
                      isCurrentTime
                        ? 'chakra-primary-text'
                        : 'chakra-subtle-text'
                    }
                  >
                    <VStack
                      justifyContent={'space-between'}
                      h={'full'}
                      gap={6}
                      fontSize={{ base: 'xs', md: 'sm', lg: 'md' }}
                    >
                      <Text>{startTime}</Text>
                      <IoArrowDown />
                      <Text>{endTime}</Text>
                    </VStack>
                  </GridItem>
                  {myClass !== null ? (
                    <GridItem colStart={2}>
                      <TimelineClassCard myClass={myClass} />
                    </GridItem>
                  ) : (
                    <GridItem colStart={2}>
                      <TimelineEmptyCard />
                    </GridItem>
                  )}
                </GridItem>
              );
            })}
          </SectionContents>
        </Section>
      </SectionStack>
    </MainContainer>
  );
});

export default Timeline;
