import {
  Box,
  Button,
  CardBody,
  CardFooter,
  HStack,
  Heading,
  Highlight,
  Text,
} from '@chakra-ui/react';
import { FlatColors } from 'app/colors';
import { MyClassInfo, type ClassInfo } from 'app/hupassApi';
import routes from 'app/routes';
import { PeriodToStr, getHighlightWords, toHalfString } from 'app/utils';
import ClassInfoBadges from 'components/common/badges/ClassInfoBadges';
import { ClassCard } from 'components/common/card/ClassCard';
import ClassLinkButons from 'components/common/form/ClassLinkButons';
import InformationList from 'components/common/list/InformationList';
import { getErrorToastOptions, toast } from 'components/common/toast/toast';
import { memo, useState } from 'react';
import { IoSchool, IoToday } from 'react-icons/io5';
import { Link, useSearchParams } from 'react-router-dom';

export interface SearchClassCardProps {
  cls: ClassInfo;
  myClass?: MyClassInfo;
  enroll: (cls_id: number) => Promise<void>;
  unenroll: (cls_id: number) => Promise<void>;
}

const SearchClassCard = memo(function SearchClassCard(
  props: SearchClassCardProps
) {
  const { myClass, cls, enroll, unenroll } = props;
  const [params] = useSearchParams();
  const [pending, setPending] = useState(false);
  const listItems: { icon: React.ReactNode; text: string }[] = [
    {
      icon: <IoToday />,
      text: cls.periods.map((period) => PeriodToStr(period)).join(', '),
    },
    {
      icon: <IoSchool />,
      text: cls.teacher || '教員情報なし',
    },
  ];

  return (
    <ClassCard classColor={myClass?.color ?? FlatColors.silver}>
      <Link
        to={`${routes.search_detail.replace(
          ':id',
          String(cls?.id)
        )}?${params.toString()}`}
      >
        <CardBody display="flex" flexDir="column" gap={2} p={4}>
          <Text fontSize="sm" color="chakra-subtle-text" fontWeight={'bold'}>
            {cls.year}年度 {toHalfString(String(cls.semester))}
          </Text>
          <Box>
            <Heading fontSize="xl">
              <Highlight
                query={getHighlightWords(params)}
                styles={{ bgColor: 'chakra-highlight-color' }}
              >
                {cls.subject}
              </Highlight>
            </Heading>
            <Text color="chakra-subtle-text">
              <Highlight
                query={getHighlightWords(params)}
                styles={{ bgColor: 'chakra-highlight-color' }}
              >
                {cls?.theme ?? ''}
              </Highlight>
            </Text>
          </Box>
          <Box>
            <Text
              color="chakra-subtle-text"
              fontSize={'sm'}
              fontWeight={'bold'}
            >
              <Highlight
                query={getHighlightWords(params)}
                styles={{ bgColor: 'chakra-highlight-color' }}
              >
                {`${cls.elig_dep ?? ''} ${
                  cls.elig_min ? `${cls.elig_min}年生` : ''
                }
                ${
                  cls.elig_min !== cls.elig_max
                    ? ` ~ ${cls.elig_max ? `${cls.elig_max}年生` : ''}`
                    : ''
                }`}
              </Highlight>
            </Text>
          </Box>
          <HStack flexWrap={'wrap'}>
            <ClassInfoBadges cls={cls} />
          </HStack>
          <InformationList highlight items={listItems} />
        </CardBody>
      </Link>
      <CardFooter p={2} justifyContent={'space-between'} gap={2} mt={'auto'}>
        <ClassLinkButons myClass={myClass} cls={cls} />
        {myClass ? (
          <Button
            onClick={() => {
              setPending(true);
              toast.promise(
                unenroll(cls.id).finally(() => setPending(false)),
                {
                  success: { title: '授業を削除しました' },
                  loading: { title: '授業を削除中' },
                  error: (e) => ({
                    ...getErrorToastOptions(e),
                    title: '授業を削除しました',
                  }),
                }
              );
            }}
            colorScheme="red"
            flex={1}
            disabled={pending}
          >
            削除
          </Button>
        ) : (
          <Button
            onClick={() => {
              setPending(true);
              toast.promise(
                enroll(cls.id).finally(() => setPending(false)),
                {
                  success: { title: '授業を登録しました' },
                  loading: { title: '授業を登録中' },
                  error: (e) => ({
                    ...getErrorToastOptions(e),
                    title: '授業を登録しました',
                  }),
                }
              );
            }}
            colorScheme="blue"
            flex={1}
            disabled={pending}
          >
            登録
          </Button>
        )}
      </CardFooter>
    </ClassCard>
  );
});

export default SearchClassCard;
