import { Heading, type HeadingProps } from '@chakra-ui/react';
import { memo } from 'react';

const ClassSubtitle = memo(function ClassSubTitle(props: HeadingProps) {
  return (
    <Heading
      fontSize={['2xs', 'xs', 'sm']}
      color="chakra-subtle-text"
      noOfLines={3}
      {...props}
    />
  );
});

export default ClassSubtitle;
