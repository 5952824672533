import { defineStyle, defineStyleConfig } from '@chakra-ui/react';
import { mode, transparentize } from '@chakra-ui/theme-tools';

const baseStyle = defineStyle({});

const variantSoft = defineStyle((props) => {
  const { colorScheme: c, theme } = props;

  const darkBg = transparentize(`${c}.200`, 0.12)(theme);
  const darkHoverBg = transparentize(`${c}.200`, 0.24)(theme);

  return {
    bg: mode(`${c}.50`, darkBg)(props),
    color: mode(`${c}.600`, `${c}.100`)(props),
    _hover: {
      bg: mode(`${c}.100`, darkHoverBg)(props),
    },
    _active: {
      bg: mode(`${c}.500`, `${c}.200`)(props),
      color: mode('white', 'gray.800')(props),
    },
  };
});

const variants = {
  soft: variantSoft,
};

export const buttonTheme = defineStyleConfig({
  baseStyle,
  variants,
});
