import {
  AspectRatio,
  Box,
  Button,
  Card,
  CardBody,
  Container,
  DarkMode,
  Divider,
  Flex,
  Heading,
  Icon,
  Image,
  SimpleGrid,
  Stack,
  Text,
  VStack,
  useBreakpointValue,
  useColorModeValue,
} from '@chakra-ui/react';
import { memo, useEffect } from 'react';
import { FcBarChart, FcMultipleDevices, FcSynchronize } from 'react-icons/fc';
import { IoOpenOutline } from 'react-icons/io5';
import { Link } from 'react-router-dom';
import routes from 'app/routes';
import HupassLogo, { useLogoColor } from 'components/common/HupassLogo';
import { useTitle } from 'app/hooks/title';
import { useSetRecoilState } from 'recoil';
import { appOptionsState, defaultAppOptions } from 'App';

const Landing = memo(function Landing() {
  useTitle('北大生のための時間割アプリ');
  const setAppOptions = useSetRecoilState(appOptionsState);
  useEffect(() => {
    setAppOptions({ header: true, footer: true, navigation: false });

    return () => {
      setAppOptions(defaultAppOptions);
    };
  }, [setAppOptions]);

  return (
    <>
      <Box bgColor={useColorModeValue('blue.50', 'blue.900')}>
        <Stack
          as={Container}
          maxW="3xl"
          textAlign="center"
          alignItems="center"
          spacing={{ base: 8, md: 14 }}
          py={{ base: 8, md: 14 }}
          px={4}
        >
          <HupassLogo size={useBreakpointValue({ base: 2, md: 3 })} />
          <Heading
            fontWeight={600}
            fontSize={{ base: '4xl', sm: '5xl', md: '6xl' }}
            lineHeight="175%"
          >
            北大生のための <br />
            <Text as="span" color={useLogoColor()}>
              時間割アプリ
            </Text>
          </Heading>
          <Text color="chakra-subtle-text">
            北大生による北大生のための時間割管理サービス
          </Text>
          <Stack
            direction="column"
            spacing={3}
            align="center"
            alignSelf="center"
            position="relative"
          >
            <Button
              colorScheme="blue"
              size="lg"
              rounded="full"
              px={10}
              as={Link}
              to={routes.timetable}
              shadow="xl"
            >
              時間割を作る
            </Button>
          </Stack>
        </Stack>
      </Box>
      <Container maxW="7xl">
        <VStack py={{ base: 8, md: 14 }} px={2} spacing={{ base: 6, md: 8 }}>
          <Heading color="chakra-subtle-text">特徴</Heading>
          <SimpleGrid columns={{ base: 1, md: 3 }} spacing={8} w="full">
            {[
              {
                icon: FcBarChart,
                title: '成績を見ながら',
                text: 'iNAZOと連携して、平均GPAやA帯の人数から授業を選ぶことができます。',
              },
              {
                icon: FcMultipleDevices,
                title: 'マルチデバイス',
                text: 'Webアプリなので、スマホからもパソコンからもアクセスできます。',
              },
              {
                icon: FcSynchronize,
                title: 'データの同期',
                text: 'Googleアカウントでログインすることで、データの同期もできます。',
              },
            ].map(({ icon, title, text }) => (
              <Card
                key={title}
                // variant={"filled"}
                rounded="2xl"
                bgGradient="linear(teal.400, teal.600)"
                color="white"
                // {...colorShadow("teal.500")}
                maxW="md"
                mx="auto"
              >
                <CardBody as={VStack} spacing={4}>
                  <Icon as={icon} fontSize="8xl" />
                  <Heading fontSize="xl" textAlign="center">
                    {title}
                  </Heading>
                  <Divider borderColor="whiteAlpha.400" />
                  <Text opacity={0.8}>{text}</Text>
                </CardBody>
              </Card>
            ))}
          </SimpleGrid>
        </VStack>
        <VStack py={{ base: 8, md: 14 }} px={2} spacing={{ base: 6, md: 8 }}>
          <Heading color="chakra-subtle-text">Powered By JagaJaga</Heading>
          <Card
            // variant={"filled"}
            rounded="2xl"
            bgGradient="linear(green.400, green.600)"
            color="white"
            as="a"
            target="blank"
            href="https://hu-jagajaga.com"
            transition="all ease 0.3s"
            maxW={{ base: 'md', md: 'none' }}
            mx="auto"
            // {...colorShadow("green.500")}
          >
            <CardBody
              as={Stack}
              direction={{ base: 'column-reverse', md: 'row' }}
              spacing={8}
              alignItems="center"
            >
              <Flex
                p={{ base: 0, md: 4 }}
                direction="column"
                justifyContent="center"
                w={{ base: '100%', md: '70%' }}
              >
                <Heading mb={4}>JagaJaga</Heading>
                <VStack spacing={2} alignItems="start" opacity={0.8}>
                  <Text>
                    JagaJagaは北大生の大学生活を応援する北大初の総合メディアです。
                  </Text>
                  <Text>
                    運営は現役北大生で構成されているJagaJaga編集部が行っています。
                  </Text>
                  <Text>
                    北大を取り巻くすべての人へ情報を届けるべく、未来の北大生に向けたお役立ち情報、魅力的な北大生や北大の団体・イベントの紹介、北大卒業生へのインタビュー記事などを製作しています。
                  </Text>
                </VStack>
                <DarkMode>
                  <Button
                    mt={4}
                    variant="solid"
                    colorScheme="whiteAlpha"
                    rounded="lg"
                    rightIcon={<IoOpenOutline />}
                    alignSelf="flex-end"
                    as="div"
                    color="white"
                  >
                    Open
                  </Button>
                </DarkMode>
              </Flex>
              <AspectRatio
                w={{ base: '50%', md: '30%' }}
                ratio={1}
                bg="white"
                rounded="3xl"
              >
                <Box p={4}>
                  <Image objectFit="cover" src="/img/JagaJaga-logo.svg" />
                </Box>
              </AspectRatio>
            </CardBody>
          </Card>
        </VStack>
      </Container>
    </>
  );
});

export default Landing;
