import { Card, CardBody, CardProps, Center, Text } from '@chakra-ui/react';

const ResultNonSelectionCard: React.FC<CardProps> = (props) => {
  return (
    <Card {...props}>
      <CardBody
        display="flex"
        flexDir="column"
        gap={2}
        p={4}
        alignItems="center"
        justifyContent="center"
      >
        <Center>
          <Text size="md">選択しない</Text>
        </Center>
      </CardBody>
    </Card>
  );
};

export default ResultNonSelectionCard;
