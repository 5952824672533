import { Box, Highlight, List, ListItem, ListProps } from '@chakra-ui/react';
import { getHighlightWords } from 'app/utils';
import { memo } from 'react';
import { useSearchParams } from 'react-router-dom';

export type InformationListProps = ListProps & {
  items: { icon: React.ReactNode; text: string }[];
  highlight?: boolean;
};

export default memo(function InformationList(props: InformationListProps) {
  const { items, highlight, ...listProps } = props;
  const [params] = useSearchParams();
  return (
    <List {...listProps}>
      {items.map(({ icon, text }, index) => (
        <ListItem
          key={index}
          display={'flex'}
          gap={2}
          alignItems={'center'}
          color={'chakra-subtle-text'}
        >
          <Box flexShrink={0}>{icon}</Box>
          <Highlight
            query={(highlight && getHighlightWords(params)) || ''}
            styles={{ bgColor: 'chakra-highlight-color' }}
          >
            {text}
          </Highlight>
        </ListItem>
      ))}
    </List>
  );
});
