import { Center } from '@chakra-ui/react';
import { ClassCard } from 'components/common/card/ClassCard';
import { memo } from 'react';

export type TimelineEmptyCardProps = Record<string, never>;

export default memo(function TimelineEmptyCard() {
  return (
    <ClassCard variant={'outline'}>
      <Center flex={1}>空きコマ</Center>
    </ClassCard>
  );
});
