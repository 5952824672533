import {
  FormControl,
  FormLabel,
  HStack,
  UseRadioGroupProps,
  useRadioGroup,
} from '@chakra-ui/react';
import { memo } from 'react';
import SemesterRadioButton from './SemesterRadioButton';

const SemesterSelector = memo(function SemesterSelecor(
  props: UseRadioGroupProps
) {
  const { getRootProps, getRadioProps } = useRadioGroup(props);

  return (
    <FormControl>
      <FormLabel>ターム</FormLabel>
      <HStack {...getRootProps()}>
        <SemesterRadioButton
          colorScheme={'teal'}
          {...getRadioProps({ value: '1' })}
        >
          春
        </SemesterRadioButton>
        <SemesterRadioButton
          colorScheme={'cyan'}
          {...getRadioProps({ value: '2' })}
        >
          夏
        </SemesterRadioButton>
        <SemesterRadioButton
          colorScheme={'orange'}
          {...getRadioProps({ value: '3' })}
        >
          秋
        </SemesterRadioButton>
        <SemesterRadioButton
          colorScheme={'blue'}
          {...getRadioProps({ value: '4' })}
        >
          冬
        </SemesterRadioButton>
      </HStack>
    </FormControl>
  );
});

export default SemesterSelector;
