import { Box, HStack, IconButton, TextareaProps } from '@chakra-ui/react';
import { memo, useEffect, useRef } from 'react';
import {
  TbArrowBarLeft,
  TbArrowBarRight,
  TbBold,
  TbH1,
  TbH2,
  TbH3,
  TbItalic,
  TbLink,
  TbList,
  TbListCheck,
  TbListNumbers,
} from 'react-icons/tb';
import AutoResizeTextArea from './AutoResizeTextArea';

export type MarkdownEditorProps = {
  value?: string;
  onChange?: React.Dispatch<React.SetStateAction<string>>;
} & Omit<TextareaProps, 'value' | 'onChange'>;

let selectionStart = 0;
let isCompose = false;

export default memo(function MarkdownEditor(props: MarkdownEditorProps) {
  const { value, onChange, ...others } = props;
  const ref = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (selectionStart > 0) {
      ref.current?.setSelectionRange(selectionStart, selectionStart);
      selectionStart = 0;
    }
  }, [value]);

  const handleKeyDown: React.KeyboardEventHandler<HTMLTextAreaElement> = (
    e
  ) => {
    const textareaElement = ref.current;
    if (!textareaElement || value === undefined) return;
    let insert = '';
    const substr = (start: number, end?: number) => value.substring(start, end);
    const start = textareaElement.selectionStart;
    const end = textareaElement.selectionEnd;
    if (e.key === 'Tab') {
      e.preventDefault();
      insert = '  ';
      selectionStart = start + 2;
    }
    if (e.key === 'Enter' && !e.ctrlKey && !isCompose) {
      const match = substr(0, start)
        .split(/(\r?\n)+?/)
        .slice(-1)[0]
        .match(/^( *((\*|-|\d.)? (\[[ x]\] )?))/)?.[0];
      if (!match) return;
      e.preventDefault();
      insert = '\n' + match;
      selectionStart = start + match.length + 1;
    }
    if (!insert) return;
    onChange?.(substr(0, start) + insert + substr(end));
  };

  const clickInsertHandler =
    (
      text: string,
      focutPosition: number = 0
    ): React.MouseEventHandler<HTMLButtonElement> =>
    () => {
      const textareaElement = ref.current;
      if (!textareaElement || value === undefined) return;
      const substr = (start: number, end?: number) =>
        value.substring(start, end);

      const start = textareaElement.selectionStart;
      const end = textareaElement.selectionEnd;
      if (text === 'indent' || text === '-indent') {
        const lines = substr(0, start).split(/(r?\n)+?/);
        if (text.substring(0, 1) !== '-') {
          lines[lines.length - 1] = '  ' + lines[lines.length - 1];
          selectionStart = start + 2;
        } else if (lines[lines.length - 1].match(/^ {2}/)) {
          lines[lines.length - 1] = lines[lines.length - 1].replace(
            /^ {2}/,
            ''
          );
          selectionStart = start - 2;
        }
        onChange?.(lines.join('\n') + substr(end));
      } else {
        onChange?.(
          substr(0, start) +
            text.slice(0, focutPosition) +
            substr(start, end) +
            text.slice(focutPosition) +
            substr(end)
        );
        selectionStart = start + text.length + focutPosition;
      }
      textareaElement.focus();
    };

  return (
    <Box>
      <HStack mb={4} overflowX={'auto'}>
        <IconButton aria-label="insert h1" onClick={clickInsertHandler('# ')}>
          <TbH1 />
        </IconButton>
        <IconButton aria-label="insert h2" onClick={clickInsertHandler('## ')}>
          <TbH2 />
        </IconButton>
        <IconButton aria-label="insert h3" onClick={clickInsertHandler('### ')}>
          <TbH3 />
        </IconButton>
        <IconButton
          aria-label="insert bold text"
          onClick={clickInsertHandler('****', -2)}
        >
          <TbBold />
        </IconButton>
        <IconButton
          aria-label="insert italic text"
          onClick={clickInsertHandler('**', -1)}
        >
          <TbItalic />
        </IconButton>
        <IconButton
          aria-label="insert link"
          onClick={clickInsertHandler('[]()', -3)}
        >
          <TbLink />
        </IconButton>
        <IconButton aria-label="insert list" onClick={clickInsertHandler('* ')}>
          <TbList />
        </IconButton>
        <IconButton
          aria-label="insert number list"
          onClick={clickInsertHandler('1. ')}
        >
          <TbListNumbers />
        </IconButton>
        <IconButton
          aria-label="insert check list"
          onClick={clickInsertHandler('- [ ] ')}
        >
          <TbListCheck />
        </IconButton>
        <IconButton aria-label="indent" onClick={clickInsertHandler('indent')}>
          <TbArrowBarRight />
        </IconButton>
        <IconButton aria-label="indent" onClick={clickInsertHandler('-indent')}>
          <TbArrowBarLeft />
        </IconButton>
      </HStack>
      <AutoResizeTextArea
        ref={ref}
        value={value}
        onChange={(e) => {
          onChange?.(e.target.value);
        }}
        onKeyDown={handleKeyDown}
        onCompositionStart={() => (isCompose = true)}
        onCompositionEnd={() => (isCompose = false)}
        fontFamily={'mono'}
        {...others}
      />
    </Box>
  );
});
