import {
  Button,
  Card,
  CardBody,
  Container,
  Flex,
  Heading,
  Icon,
  Link,
  List,
  ListIcon,
  ListItem,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
  useToast,
} from '@chakra-ui/react';
import hupassApi from 'app/hupassApi';
import routes from 'app/routes';
import { getErrorToastOptions } from 'components/common/toast/toast';
import { useEffect, useRef, useState } from 'react';
import { useCookies } from 'react-cookie';
import { FcGoogle } from 'react-icons/fc';
import { IoOpenOutline, IoReaderOutline } from 'react-icons/io5';
import { useNavigate, useSearchParams } from 'react-router-dom';

export default function Login() {
  const [params] = useSearchParams();
  const pending = useRef(false);
  const [status, setStatus] = useState<
    'idle' | 'pending' | 'success' | 'failed'
  >('idle');
  const toast = useToast();
  const [cookies, setCookie] = useCookies(['token', 'user']);
  const navigate = useNavigate();

  useEffect(() => {
    if (params.get('code') && !pending.current) {
      pending.current = true;
      hupassApi
        .ConvertToken({
          code: params.get('code') ?? '',
          provider: 'google-oauth2',
          redirect_uri: `${window.location.protocol}//${window.location.host}/login`,
        })
        .then(
          (res: { data: { user: string; token: string } }) => {
            setCookie('user', res.data.user, { maxAge: 60 * 60 * 24 * 365 });
            setCookie('token', res.data.token, { maxAge: 60 * 60 * 24 * 365 });
            navigate(routes.timetable);
          },
          (e) => toast(getErrorToastOptions(e as Error))
        );
    }
  }, [params, pending, toast, setStatus, cookies, navigate, setCookie]);

  return (
    <Container
      maxW="7xl"
      as={Flex}
      direction="column"
      gap={4}
      py={4}
      alignItems="center"
    >
      <Heading>ログイン</Heading>
      <Text>Googleアカウントでログインできます。</Text>
      <Text>ログインしたことで以下の規約に同意したものとみなします。</Text>
      <Card variant="filled">
        <CardBody>
          <List as={Flex} gap={3} direction="column">
            <ListItem
              as={Link}
              href="https://hackmd.io/@cfs7kHkXR_2V_ACvnozJeg/HyppQXS1h"
              isExternal
            >
              <ListIcon as={IoReaderOutline} />
              利用規約
              <Icon as={IoOpenOutline} ml={2} verticalAlign="text-bottom" />
            </ListItem>
            <ListItem
              as={Link}
              href="https://hackmd.io/@cfs7kHkXR_2V_ACvnozJeg/rkPeV7ry3"
              isExternal
            >
              <ListIcon as={IoReaderOutline} />
              プライバシーポリシー
              <Icon as={IoOpenOutline} ml={2} verticalAlign="text-bottom" />
            </ListItem>
          </List>
        </CardBody>
      </Card>
      <Button
        my={8}
        leftIcon={<FcGoogle />}
        variant="outline"
        rounded="full"
        as="a"
        href={`https://accounts.google.com/o/oauth2/v2/auth?${new URLSearchParams(
          {
            scope: 'profile email',
            access_type: 'offline',
            include_granted_scopes: 'true',
            response_type: 'code',
            redirect_uri: `${window.location.protocol}//${window.location.host}/login`,
            client_id: import.meta.env.VITE_GOOGLE_CLIENT_ID ?? '',
          }
        ).toString()}`}
      >
        Signin with Google
      </Button>
      <Modal isOpen={status === 'pending'} onClose={() => {}}>
        <ModalOverlay />
        <ModalContent>
          <ModalBody p={8}>
            <Heading fontSize="xl">サーバーと通信中</Heading>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Container>
  );
}
