import { Button, Center, GridItem, SimpleGrid } from '@chakra-ui/react';
import { useMyClasses } from 'app/hooks/myClasses';
import { useTitle } from 'app/hooks/title';
import routes from 'app/routes';
import MainContainer from 'components/common/container/MainContainer';
import Section from 'components/common/section/Section';
import SectionContents from 'components/common/section/SectionContents';
import SectionHeading from 'components/common/section/SectionHeading';
import SectionStack from 'components/common/section/SectionStack';
import { getErrorToastOptions, toast } from 'components/common/toast/toast';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ResultNonSelectionCard from './card/ResultNonSelectionCard';
import ResultOptionCard, {
  type GeneralClassInfo,
} from './card/ResultOptionCard';

/** 全学クラスのリスト */
export interface GeneralClassList {
  /** 科目名 */
  subject: string;
  /** クラス情報 */
  classes: GeneralClassInfo[];
}

export default function Result() {
  useTitle('全学授業一括登録');
  // ページ遷移時に渡されたデータを取得
  const location = useLocation();
  const data = (location.state as { data: Array<GeneralClassList> }).data;

  const initialSelectedClasses = data.map(
    (item: GeneralClassList) => item.classes[0].id
  );

  // 選択された授業のIDを保持するリスト(選択しない: -1)
  const [selectedClasses, setSelectedClasses] = useState(
    initialSelectedClasses
  );

  const { enrollMultiple } = useMyClasses();
  const [pending, setPending] = useState(false);
  const navigate = useNavigate();

  // カードクリックによる授業選択の状態を更新
  const handleCardClick = (subjectIndex: number, classIndex: number) => {
    const newSelectedClasses = [...selectedClasses];
    newSelectedClasses[subjectIndex] =
      classIndex === -1 ? -1 : data[subjectIndex].classes[classIndex].id;
    setSelectedClasses(newSelectedClasses);
  };

  // 選択された授業を登録
  const handleEnrollMultiple = () => {
    // "選択しない"を除く
    const filteredClasses = selectedClasses.filter(
      (cls_id: number) => cls_id !== -1
    );

    setPending(true);

    toast.promise(
      enrollMultiple(filteredClasses).finally(() => {
        setPending(false);
        // timetableへ遷移
        navigate(routes.timetable);
      }),
      {
        success: { title: '授業を登録しました' },
        loading: { title: '授業を登録中' },
        error: (e) => ({
          ...getErrorToastOptions(e),
          title: '時間が重複している授業の登録はスキップされました',
        }),
      }
    );
  };

  return (
    <MainContainer>
      {data.length > 0 ? (
        <SectionStack>
          {data.map((item: GeneralClassList, subjectIndex: number) => (
            <Section key={subjectIndex}>
              <SectionHeading>{item.subject}</SectionHeading>
              <SimpleGrid
                as={SectionContents}
                gap={4}
                minChildWidth={'grid.md'}
                autoRows={'1fr'}
              >
                {item.classes.map((cls: GeneralClassInfo, clsIndex: number) => (
                  <GridItem
                    as={ResultOptionCard}
                    key={clsIndex}
                    cls={cls}
                    variant={
                      selectedClasses[subjectIndex] ===
                      data[subjectIndex].classes[clsIndex].id
                        ? 'filled'
                        : 'outline'
                    }
                    borderColor={
                      selectedClasses[subjectIndex] ===
                      data[subjectIndex].classes[clsIndex].id
                        ? 'blue.500'
                        : undefined
                    }
                    borderWidth="1.5px"
                    onClick={() => handleCardClick(subjectIndex, clsIndex)}
                    transitionProperty={'all'}
                    transitionTimingFunction={'ease-in-out'}
                    transitionDuration={'fast'}
                  />
                ))}
                <GridItem
                  as={ResultNonSelectionCard}
                  key={-1}
                  variant={
                    selectedClasses[subjectIndex] === -1 ? 'filled' : 'outline'
                  }
                  borderColor={
                    selectedClasses[subjectIndex] === -1
                      ? 'blue.500'
                      : undefined
                  }
                  borderWidth="1.5px"
                  onClick={() => handleCardClick(subjectIndex, -1)}
                />
              </SimpleGrid>
            </Section>
          ))}
          <Section>
            <SectionContents>
              <Center>
                <Button onClick={handleEnrollMultiple} isDisabled={pending}>
                  選択したものを登録
                </Button>
              </Center>
            </SectionContents>
          </Section>
        </SectionStack>
      ) : (
        <Section>
          <SectionContents>
            <Center>授業が見つかりませんでした</Center>
          </SectionContents>
          <SectionContents>
            <Center>
              <Button onClick={() => navigate(-1)}>戻る</Button>
            </Center>
          </SectionContents>
        </Section>
      )}
    </MainContainer>
  );
}
