import { HStack, UseRadioGroupProps, useRadioGroup } from '@chakra-ui/react';
import { memo } from 'react';
import ColorRadioButton from './ColorRadioButton';

export type ColorSelectorProps = UseRadioGroupProps & {
  options: string[];
};

export default memo(function ColorSelector(props: ColorSelectorProps) {
  const { getRootProps, getRadioProps } = useRadioGroup(props);

  return (
    <HStack {...getRootProps()} flexWrap={'wrap'}>
      {props.options.map((value, i) => (
        <ColorRadioButton key={i} {...getRadioProps({ value })} />
      ))}
    </HStack>
  );
});
