import { type ThemeConfig, extendTheme } from '@chakra-ui/react';
import { components } from './components';
import { foundations } from './foundations';
import { semanticTokens } from './semantic-token';
import { styles } from './styles';

const config: ThemeConfig = {
  useSystemColorMode: true,
  initialColorMode:
    localStorage.getItem('chakra-ui-color-mode') !== null
      ? localStorage.getItem('chakra-ui-color-mode') === 'dark'
        ? 'dark'
        : 'light'
      : 'system',
};

export default extendTheme({
  semanticTokens,
  ...foundations,
  styles,
  components,
  config,
});
