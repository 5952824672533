import {
  CardBody,
  HStack,
  Heading,
  Skeleton,
  Text,
  VStack,
} from '@chakra-ui/react';
import { FlatColors } from 'app/colors';
import { thisSemester, thisYear } from 'app/date';
import { type ClassInfo, type MyClassInfo } from 'app/hupassApi';
import { messages } from 'app/messages';
import { SemesterToStr, toHalfString } from 'app/utils';
import ClassInfoBadges from 'components/common/badges/ClassInfoBadges';
import { ClassCard } from 'components/common/card/ClassCard';
import ClassLinkButons from 'components/common/form/ClassLinkButons';
import InformationList from 'components/common/list/InformationList';
import Section from 'components/common/section/Section';
import SectionContents from 'components/common/section/SectionContents';
import SectionHeading from 'components/common/section/SectionHeading';
import { memo } from 'react';
import { IoLocation, IoSchool } from 'react-icons/io5';
import InformationEditor from './InformationEditor';

export type InformationProps = {
  cls?: ClassInfo;
  myClass?: MyClassInfo;
  update?: (
    newMyClass: MyClassInfo,
    controller?: AbortController
  ) => Promise<void>;
  isLoaded?: boolean;
};

export default memo(function ClassInfo(props: InformationProps) {
  const { cls, myClass, isLoaded, update } = props;
  const listItems: { icon: React.ReactNode; text: string }[] = [
    {
      icon: <IoSchool />,
      text: myClass?.c_teacher || cls?.teacher || '教員情報なし',
    },
    {
      icon: <IoLocation />,
      text: myClass?.c_place
        ? myClass.c_place
        : cls?.place && cls?.place !== ''
          ? `${cls?.place}が多く登録されています`
          : '教室情報なし',
    },
  ];

  return (
    <Section>
      <SectionHeading
        display={'flex'}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        講義情報
        {myClass && update && (
          <InformationEditor myClass={myClass} update={update} />
        )}
      </SectionHeading>
      <SectionContents>
        <ClassCard classColor={myClass?.color ?? FlatColors.silver}>
          <CardBody>
            <VStack alignItems="stretch" spacing={4}>
              <Skeleton isLoaded={isLoaded}>
                <Text color={'chakra-subtle-text'} fontWeight={'bold'}>
                  {cls?.year ?? thisYear}年度{' '}
                  {toHalfString(cls?.semester ?? SemesterToStr(thisSemester))}
                </Text>
              </Skeleton>
              <VStack alignItems={'stretch'} gap={0}>
                <Skeleton isLoaded={isLoaded}>
                  <Heading size="lg" as="h1">
                    {myClass?.c_subject ?? cls?.subject ?? messages.loading}
                  </Heading>
                </Skeleton>
                <Skeleton isLoaded={isLoaded}>
                  <Text color="chakra-subtle-text">
                    {myClass?.c_theme ?? cls?.theme}
                  </Text>
                </Skeleton>
              </VStack>
              <Skeleton isLoaded={isLoaded}>
                <HStack>
                  <ClassInfoBadges cls={cls} />
                </HStack>
              </Skeleton>
              <InformationList spacing={1} items={listItems} />
            </VStack>
          </CardBody>
        </ClassCard>
      </SectionContents>
      <SectionContents>
        <HStack wrap={'wrap'}>
          <ClassLinkButons myClass={myClass} cls={cls} />
        </HStack>
      </SectionContents>
    </Section>
  );
});
