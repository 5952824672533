import { Center } from '@chakra-ui/react';
import routes from 'app/routes';
import { memo } from 'react';
import { IoAdd } from 'react-icons/io5';
import { Link } from 'react-router-dom';
import TimetableClassCard from './TimetableClassCard';

export interface EmptyClassCardProps {
  year: number;
  semester: string;
  period: string;
}

const EmptyClassCard = memo(function EmptyClassCard(
  props: EmptyClassCardProps
) {
  const { year, semester, period } = props;

  return (
    <Link
      to={`${routes.search}?default=true&year=${year}&semester=${semester}&period=${period}`}
    >
      <TimetableClassCard variant="outline">
        <Center flex={1}>
          <IoAdd />
        </Center>
      </TimetableClassCard>
    </Link>
  );
});

export default EmptyClassCard;
