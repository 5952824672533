import { DrawerBody, type ModalBodyProps } from '@chakra-ui/react';
import { memo } from 'react';

const BottomDrawerBody = memo(function BottomDrawerBody(props: ModalBodyProps) {
  return (
    <DrawerBody pb="calc(env(safe-area-inset-bottom) + 16px)" {...props} />
  );
});

export default BottomDrawerBody;
