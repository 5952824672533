import { theme } from '@chakra-ui/react';

const sizes = {
  grid: {
    '3xs': `min(${theme.sizes['3xs']}, 100%)`,
    '2xs': `min(${theme.sizes['2xs']}, 100%)`,
    xs: `min(${theme.sizes.xs}, 100%)`,
    sm: `min(${theme.sizes.sm}, 100%)`,
    md: `min(${theme.sizes.md}, 100%)`,
    lg: `min(${theme.sizes.xl}, 100%)`,
    xl: `min(${theme.sizes.lg}, 100%)`,
    '2xl': `min(${theme.sizes['2xl']}, 100%)`,
    '3xl': `min(${theme.sizes['3xl']}, 100%)`,
    '4xl': `min(${theme.sizes['4xl']}, 100%)`,
    '5xl': `min(${theme.sizes['5xl']}, 100%)`,
    '6xl': `min(${theme.sizes['6xl']}, 100%)`,
    '7xl': `min(${theme.sizes['7xl']}, 100%)`,
    '8xl': `min(${theme.sizes['8xl']}, 100%)`,
  },
};

export default sizes;
