import {
  Box,
  createStandaloneToast,
  type UseToastOptions,
} from '@chakra-ui/react';
import { isAxiosError } from 'axios';
import theme from 'theme';

export const defaultOptions: UseToastOptions = {
  position: 'top-right',
  isClosable: true,
};

export const { ToastContainer, toast } = createStandaloneToast({
  theme,
  defaultOptions,
});

export const getErrorToastDescription = (e: Error): React.ReactNode => {
  if (isAxiosError<unknown>(e)) {
    const data = e.response?.data;
    if (data instanceof Object) {
      return Object.values(data).join();
    } else if (typeof data === 'string') {
      return <Box w={'full'} h={'full'} as="iframe" srcDoc={data} />;
    }
  }
  if (Object.entries(e).length !== 0)
    return (
      <Box
        as="pre"
        fontFamily={'mono'}
        overflow={'auto'}
        w={'full'}
        maxW={'90vw'}
        h={'full'}
      >
        {JSON.stringify(e, null, 2)}
      </Box>
    );

  return '';
};

export const getErrorToastOptions = (e: Error): UseToastOptions => {
  return {
    status: 'error',
    title: e.message,
    description: getErrorToastDescription(e),
    duration: e.message === 'canceled' ? 0 : undefined,
  };
};

export const errorHandler = (e: Error) =>
  e.message !== 'canceled' && toast(getErrorToastOptions(e));
