import { defineStyle } from '@chakra-ui/react';
import { type Styles } from '@chakra-ui/theme-tools';

export const styles: Styles = {
  global: defineStyle(() => ({
    body: {
      minH: '100dvh',
      paddingTop: 'env(safe-area-inset-top)',
      paddingBottom: 'env(safe-area-inset-bottom)',
      paddingLeft: 'env(safe-area-inset-left)',
      paddingRight: 'env(safe-area-inset-right)',
      pos: 'relative',
    },
  })),
};
