import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

const baseStyle = defineStyle({
  // px:2,
  // py: 0.5,
  // borderRadius: 'sm',
});

export const badgeTheme = defineStyleConfig({
  baseStyle,
});
