import {
  Box,
  Button,
  IconButton,
  Stack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import routes from 'app/routes';
import { featureEnableTodayTabState } from 'components/misc/Misc';
import {
  IoAddCircle,
  IoAddCircleOutline,
  IoCalendar,
  IoCalendarOutline,
  IoEllipsisHorizontal,
  IoEllipsisHorizontalOutline,
  IoHome,
  IoHomeOutline,
  IoSearch,
  IoSearchOutline,
  IoTime,
  IoTimeOutline,
} from 'react-icons/io5';
import { Link, useMatches } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

interface NavInfo {
  id: string | string[];
  icon: React.ReactElement;
  outlineIcon: React.ReactElement;
  text: string;
  link: string;
}

export default function Navigation() {
  const matches = useMatches();
  const bgBlue = useColorModeValue('blue.500', 'blue.600');
  const featureEnableTodayTab = useRecoilValue(featureEnableTodayTabState);

  const navs: NavInfo[] = [
    {
      id: 'home',
      icon: <IoHome />,
      outlineIcon: <IoHomeOutline />,
      text: 'ホーム',
      link: routes.home,
    },
    ...(featureEnableTodayTab
      ? [
          {
            id: 'timeline',
            icon: <IoTime />,
            outlineIcon: <IoTimeOutline />,
            text: '今日',
            link: routes.timeline,
          },
        ]
      : []),
    {
      id: ['timetable', 'timetable_detail'],
      icon: <IoCalendar />,
      outlineIcon: <IoCalendarOutline />,
      text: '時間割',
      link: routes.timetable,
    },
    {
      id: ['search', 'search_detail'],
      icon: <IoSearch />,
      outlineIcon: <IoSearchOutline />,
      text: '検索',
      link: `${routes.search}?default=true`,
    },
    {
      id: 'general',
      icon: <IoAddCircle />,
      outlineIcon: <IoAddCircleOutline />,
      text: '一括登録',
      link: routes.general,
    },
    {
      id: 'misc',
      icon: <IoEllipsisHorizontal />,
      outlineIcon: <IoEllipsisHorizontalOutline />,
      text: 'その他',
      link: routes.misc,
    },
  ];

  return (
    <Box
      as="nav"
      pos="fixed"
      top={{ base: 'unset', md: '16' }}
      bottom={0}
      left={0}
      right={{ base: 0, md: 'unset' }}
      pt={{ base: 0, md: 'env(safe-area-inset-top)' }}
      pl="env(safe-area-inset-left)"
      pr={{ base: 'env(safe-area-inset-right)', md: 0 }}
      pb={{ base: 'env(safe-area-inset-bottom)', md: 0 }}
      bgColor="chakra-body-bg"
      zIndex="docked"
    >
      <Box
        h={{ base: 14, md: 'full' }}
        w={{ base: '100%', md: 16 }}
        pb={{ base: 0, md: 'env(safe-area-inset-bottom)' }}
        borderStyle="solid"
        borderColor="chakra-border-color"
        borderTopWidth={{ base: 1, md: 0 }}
        borderRightWidth={{ base: 0, md: 1 }}
      >
        <Stack
          direction={{ base: 'row', md: 'column' }}
          justifyContent={{ base: 'space-around', md: 'flex-start' }}
          alignItems="center"
          gap={2}
          p={{ base: 2, md: 3 }}
        >
          {navs.map((info, index) =>
            matches.some(({ id }) => [info.id].flat().includes(id)) ? (
              <Button
                key={index}
                flexGrow={{ base: 1, md: 0 }}
                flexBasis={{ base: 1, md: 'unset' }}
                aria-label={info.text}
                variant="ghost"
                rounded="xl"
                colorScheme="blue"
                flexDirection="column"
                rowGap={1}
                as={Link}
                to={info.link}
                p={0}
                pos="relative"
                _hover={{
                  bgColor: 'transparent',
                }}
                _before={{
                  content: "''",
                  pos: 'absolute',
                  top: { base: -2, md: 0 },
                  right: { base: 0, md: -3 },
                  width: { base: '100%', md: 1 },
                  height: { base: 1, md: '100%' },
                  bgColor: bgBlue,
                  borderBottomRightRadius: { base: 'md', md: 'none' },
                  borderBottomLeftRadius: 'md',
                  borderTopLeftRadius: { base: 0, md: 'md' },
                }}
              >
                {info.icon}
                <Text fontSize="2xs" display="inline-block">
                  {info.text}
                </Text>
              </Button>
            ) : (
              <IconButton
                key={index}
                flexGrow={{ base: 1, md: 0 }}
                flexBasis={{ base: 1, md: 'unset' }}
                aria-label={info.text}
                icon={info.outlineIcon}
                variant="ghost"
                rounded="xl"
                as={Link}
                to={info.link}
              />
            )
          )}
        </Stack>
      </Box>
    </Box>
  );
}
