import { useCallback, useEffect, useState } from 'react';
import { AtomOptions, type AtomEffect } from 'recoil';

/**
 *
 * @param key
 * @param initValue
 * @returns
 * @deprecated 同時に変更するとバグる
 */
export const useLocalStorage = (
  key: string,
  initValue: string | (() => string)
) => {
  const [value, setValue] = useState(
    () =>
      localStorage.getItem(key) ||
      (initValue instanceof Function ? initValue() : initValue)
  );

  useEffect(() => {
    localStorage.setItem(key, value);
  }, [value, key]);

  const getValue = useCallback(() => value, [value]);

  return [getValue, setValue] as const;
};

export const useJsonLocalStorage = <T>(
  key: string,
  initValue: T | (() => T)
) => {
  const [json, setJson] = useLocalStorage(key, () =>
    JSON.stringify(initValue instanceof Function ? initValue() : initValue)
  );
  const [value, setValue] = useState<T>(JSON.parse(json()) as T);

  useEffect(() => {
    setJson(JSON.stringify(value));
  }, [value, key, setJson]);

  const getValue = useCallback(
    () => (JSON.parse(json()) as T) ?? value,
    [json, value]
  );

  return [getValue, setValue] as const;
};

export const localStorageKeys = {
  myClasses: 'myClasses',
  isLocalChange: 'isLocalChange',
  defaultParams: 'defaultParams',
  timetableYear: 'timetableYear',
  timetableSemester: 'timetableSemester',
  featureClassMemo: 'featureClassMemo',
  featureEnableTodayTab: 'featureEnableTodayTab',
  showPeriods: 'showPeriods',
  myFaculties: 'myFaculties',
};

export const getSavedValue = <T>(key: string, defaultValue: T) => {
  try {
    return (JSON.parse(String(localStorage.getItem(key))) as T) ?? defaultValue;
  } catch {
    return defaultValue;
  }
};

export const saveValue =
  <T>(key: string): AtomEffect<T> =>
  ({ onSet }) => {
    onSet((value: T) => {
      localStorage.setItem(key, JSON.stringify(value));
    });
  };

export const listenChange =
  <T>(key: string, defaultValue: T): AtomEffect<T> =>
  ({ setSelf }) => {
    const handleChangeStorage = (e: StorageEvent) => {
      if (e.storageArea === localStorage && e.key === key) {
        if (e.newValue === null) {
          setSelf(defaultValue);
        } else {
          setSelf(JSON.parse(e.newValue) as T);
        }
      }
    };

    window.addEventListener('storage', handleChangeStorage);

    return () => {
      window.removeEventListener('storage', handleChangeStorage);
    };
  };

export const atomOptionsWithLocalStorage = <T>(
  key: string,
  defaultValue: T,
  effects: AtomEffect<T>[] = []
): AtomOptions<T> => ({
  key,
  default: getSavedValue(key, defaultValue),
  effects: [saveValue(key), listenChange(key, defaultValue), ...effects],
});
