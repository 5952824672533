import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Button,
  Checkbox,
  CheckboxGroup,
  Flex,
  Radio,
  RadioGroup,
  Text,
  VStack,
  useDisclosure,
} from '@chakra-ui/react';
import { searchOptionsState } from 'app/recoil/searchOptions';
import BottomDrawer from 'components/common/drawer/BottomDrawer';
import BottomDrawerBody from 'components/common/drawer/BottomDrawerBody';
import BottomDrawerHeader from 'components/common/drawer/BottomDrawerHeader';
import PeriodForm from 'components/common/form/PeriodForm';
import { memo, useCallback } from 'react';
import { IoFilter } from 'react-icons/io5';
import { useSearchParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { localStorageKeys, useJsonLocalStorage } from 'app/hooks/localStorage';

/**
 * @todo 時限選択機能の分離
 */
const SearchOptions = memo(function SearchOptions() {
  const searchOptions = useRecoilValue(searchOptionsState);
  const [params, setParams] = useSearchParams();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [, setFaculties] = useJsonLocalStorage<string[]>(
    localStorageKeys.myFaculties,
    []
  );

  const setQuery = useCallback(
    (name: string, value: string[]) => {
      setParams((currentParams) => {
        currentParams.delete(name);
        value.forEach((v) => {
          currentParams.append(name, v);
        });
        return currentParams;
      });
    },
    [setParams]
  );

  return (
    <>
      <Button
        aria-label="open filter selector"
        onClick={onOpen}
        w="full"
        leftIcon={<IoFilter />}
        variant="link"
      >
        絞り込み
      </Button>
      <BottomDrawer isOpen={isOpen} onClose={onClose}>
        <BottomDrawerHeader onClose={onClose}>絞り込み</BottomDrawerHeader>
        <BottomDrawerBody>
          <Accordion mx={-4} allowMultiple>
            {searchOptions.map(({ name, label, type, choices }) => (
              <AccordionItem key={name}>
                <AccordionButton>
                  <Flex
                    flex={1}
                    overflow="hidden"
                    justifyContent="space-between"
                    gap={2}
                    pr={2}
                    whiteSpace="nowrap"
                  >
                    <Text fontWeight="bold">{label}</Text>
                    <Text
                      overflow="hidden"
                      textOverflow="ellipsis"
                      fontWeight="normal"
                    >
                      {params
                        .getAll(name)
                        ?.flatMap((value) =>
                          choices.flatMap((choice) =>
                            String(choice[0]) === value ? choice[1] : []
                          )
                        )
                        .join(', ')}
                    </Text>
                  </Flex>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel>
                  {type === 'choice' ? (
                    <RadioGroup
                      onChange={(value) => {
                        setQuery(name, [value]);
                      }}
                      value={params.get(name) ?? ''}
                      size={{ base: 'md', md: 'lg' }}
                    >
                      <VStack
                        alignItems="flex-start"
                        spacing={{ base: 2, md: 4 }}
                      >
                        {choices.map(([value, text]) => (
                          <Radio key={value} value={String(value)}>
                            {text}
                          </Radio>
                        ))}
                      </VStack>
                    </RadioGroup>
                  ) : (
                    <VStack alignItems="flex-start">
                      <Checkbox
                        isChecked={
                          choices.length === params.getAll(name).length
                        }
                        isIndeterminate={Boolean(
                          (choices.length - params.getAll(name).length) *
                            params.getAll(name).length
                        )}
                        fontWeight="bold"
                        onChange={(e) => {
                          if (e.target.checked) {
                            setQuery(
                              name,
                              choices.map(([value]) => String(value))
                            );

                            // 全学部をlocalStorageにセット
                            if (name === 'faculty') {
                              setFaculties(
                                choices.map(([value]) => String(value))
                              );
                            }
                          } else {
                            setQuery(name, []);

                            if (name === 'faculty') setFaculties([]);
                          }
                        }}
                        size={{ base: 'md', md: 'lg' }}
                        mb={{ base: 2, md: 4 }}
                      >
                        すべて
                      </Checkbox>
                      {name === 'period' ? (
                        <PeriodForm
                          value={params.getAll(name)}
                          onChange={(value) => {
                            setQuery(
                              name,
                              value.map((v) => v.toString())
                            );
                          }}
                          choices={choices}
                        />
                      ) : (
                        <CheckboxGroup
                          value={params.getAll(name)}
                          onChange={(value) => {
                            setQuery(
                              name,
                              value.map((v) => v.toString())
                            );

                            // checkされた学部をlocalStorageに設定する
                            if (name === 'faculty') {
                              setFaculties(value.map((v) => String(v)));
                            }
                          }}
                          size={{ base: 'md', md: 'lg' }}
                        >
                          <VStack alignItems={'stretch'} gap={4} w="full">
                            {choices.map(([value, text]) => (
                              <Flex key={value}>
                                <Checkbox value={String(value)}>
                                  {text}
                                </Checkbox>
                              </Flex>
                            ))}
                          </VStack>
                        </CheckboxGroup>
                      )}
                    </VStack>
                  )}
                </AccordionPanel>
              </AccordionItem>
            ))}
          </Accordion>
        </BottomDrawerBody>
      </BottomDrawer>
    </>
  );
});

export default SearchOptions;
