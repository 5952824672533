import { Box, Button, CardBody, Textarea } from '@chakra-ui/react';
import { MyClassInfo } from 'app/hupassApi';
import { ClassCard } from 'components/common/card/ClassCard';
import SectionHeading from 'components/common/section/SectionHeading';
import Linkify from 'linkify-react';
import { memo as reactMemo, useEffect, useRef, useState } from 'react';
import { IoCheckmark, IoPencil } from 'react-icons/io5';

export type TextMemoProps = {
  myClass: MyClassInfo;
  update?: (
    newMyClass: MyClassInfo,
    controller?: AbortController
  ) => Promise<void>;
};

export default reactMemo(function TextMemo(props: TextMemoProps) {
  const { myClass, update } = props;
  const [memo, setMemo] = useState(myClass.memo);
  const [edit, setEdit] = useState(false);
  const controller = useRef<AbortController>();
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const taskId = useRef<NodeJS.Timeout>();

  useEffect(() => {
    controller.current = new AbortController();

    return () => {
      controller.current?.abort();
    };
  }, []);

  useEffect(() => {
    if (!edit && myClass.memo !== memo) {
      update?.({ ...myClass, memo }, controller.current).catch(() => {});
    }
  }, [edit, memo, update, myClass]);

  return (
    <>
      <SectionHeading display={'flex'}>
        講義メモ
        <Button
          ml={'auto'}
          alignSelf={'flex-end'}
          variant={'link'}
          leftIcon={edit ? <IoCheckmark /> : <IoPencil />}
          onClick={() =>
            edit ? textareaRef.current?.blur() : textareaRef.current?.focus()
          }
        >
          {edit ? '完了' : '編集'}
        </Button>
      </SectionHeading>
      <ClassCard classColor={myClass.color}>
        <CardBody
          pos={'relative'}
          sx={{
            a: { pos: 'relative', zIndex: edit ? 0 : 2 },
          }}
        >
          <Textarea
            ref={textareaRef}
            w={'auto'}
            minH={'auto'}
            pos={'absolute'}
            left={5}
            right={5}
            top={3}
            bottom={3}
            zIndex={1}
            value={memo}
            onChange={(e) => setMemo(e.target.value)}
            onFocus={() => {
              clearTimeout(taskId.current);
              setEdit(true);
            }}
            onBlur={() => {
              taskId.current = setTimeout(() => {
                setEdit(false);
              }, 100);
            }}
            lineHeight={'inherit'}
            resize={'none'}
            variant={'unstyled'}
          />
          <Box
            whiteSpace={'pre-wrap'}
            sx={{ a: { color: 'chakra-link-color' } }}
          >
            <Linkify>{memo + '\n'}</Linkify>
          </Box>
        </CardBody>
      </ClassCard>
    </>
  );
});
