import hupassApi, { type UserInfo } from 'app/hupassApi';
import { useCallback, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { getErrorToastOptions, toast } from '../toast/toast';

export const useUserInfo = () => {
  const [cookies, , removeCookie] = useCookies(['user', 'token']);
  const [userInfo, setUserInfo] = useState<UserInfo>();

  useEffect(() => {
    if (
      cookies.user &&
      typeof cookies.user === 'string' &&
      cookies.token &&
      typeof cookies.token === 'string'
    ) {
      hupassApi.GetUserInfo(cookies.user, cookies.token).then(
        (res) => {
          setUserInfo(res.data);
        },
        (e) => toast(getErrorToastOptions(e as Error))
      );
    }
  }, [cookies]);

  const onLogout = useCallback(() => {
    removeCookie('user');
    removeCookie('token');
    setUserInfo(undefined);
  }, [removeCookie]);

  return { userInfo, onLogout };
};
