import {
  Box,
  Card,
  CardBody,
  CardProps,
  Heading,
  Highlight,
  Text,
} from '@chakra-ui/react';
import { PeriodToStr, getHighlightWords, toHalfString } from 'app/utils';
import InformationList from 'components/common/list/InformationList';
import { memo } from 'react';
import { IoSchool, IoToday } from 'react-icons/io5';
import { useSearchParams } from 'react-router-dom';

import { type ClassPeriod, type GradeInfo } from 'app/hupassApi';

/** 全学クラス情報 */
export interface GeneralClassInfo {
  /** クラスデータベースID */
  id: number;
  /** 科目名 */
  subject: string;
  /** 講義題目 */
  theme: string;
  /** 担当教員 */
  teacher: string;
  /** 開講年度 */
  year: number;
  /** 開講期間 */
  semester: string;
  /** 時限 */
  periods: ClassPeriod[];
  /** 成績情報 */
  grade_info: GradeInfo | null;
}

export interface ResultOptionCardProps extends CardProps {
  cls: GeneralClassInfo;
}

const ResultOptionCard = memo(function ResultOptionCard(
  props: ResultOptionCardProps
) {
  const { cls } = props;
  const [params] = useSearchParams();
  const listItems: { icon: React.ReactNode; text: string }[] = [
    {
      icon: <IoToday />,
      text: cls.periods.map((period) => PeriodToStr(period)).join(', '),
    },
    {
      icon: <IoSchool />,
      text: cls.teacher,
    },
  ];

  return (
    <Card {...props}>
      <CardBody display="flex" flexDir="column" gap={2} p={4}>
        <Text fontSize="sm" color="chakra-subtle-text" fontWeight={'bold'}>
          {cls.year}年度 {toHalfString(String(cls.semester))}
        </Text>
        <Box>
          <Heading fontSize="xl">
            <Highlight
              query={getHighlightWords(params)}
              styles={{ bgColor: 'chakra-highlight-color' }}
            >
              {cls.subject}
            </Highlight>
          </Heading>
          <Text color="chakra-subtle-text">
            <Highlight
              query={getHighlightWords(params)}
              styles={{ bgColor: 'chakra-highlight-color' }}
            >
              {cls?.theme ?? ''}
            </Highlight>
          </Text>
        </Box>
        <InformationList highlight items={listItems} />
      </CardBody>
    </Card>
  );
});

export default ResultOptionCard;
