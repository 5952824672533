import { Center, Spinner } from '@chakra-ui/react';
import { memo, useLayoutEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export default memo(function Redirect() {
  const navigate = useNavigate();

  useLayoutEffect(() => {
    navigate('/timetable');
  }, [navigate]);

  return (
    <Center>
      <Spinner />
    </Center>
  );
});
