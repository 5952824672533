import {
  Avatar,
  Box,
  Button,
  Center,
  Divider,
  Flex,
  HStack,
  Heading,
  Icon,
  IconButton,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Text,
  VStack,
  useColorMode,
  useColorModeValue,
  useDisclosure,
  type PopoverContentProps,
  type PositionProps,
  type SystemStyleObject,
} from '@chakra-ui/react';
import { useJsonLocalStorage } from 'app/hooks/localStorage';
import routes from 'app/routes';
import HupassLogo from 'components/common/HupassLogo';
import { useUserInfo } from 'components/common/header/userInfo';
import { useEffect, useState } from 'react';
import { FcImport } from 'react-icons/fc';
import {
  IoInformationCircleOutline,
  IoMoonOutline,
  IoNotificationsOutline,
  IoPersonOutline,
  IoSunnyOutline,
} from 'react-icons/io5';
import Markdown from 'react-markdown';
import { Link } from 'react-router-dom';

interface Notification {
  type: 'info' | 'alert';
  id: string;
  title?: string;
  body?: string;
}

/** HEADER */
export default function Header() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [read, setRead] = useJsonLocalStorage<string[]>(
    'readNotficationIds',
    []
  );
  const { userInfo, onLogout } = useUserInfo();
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const { toggleColorMode, colorMode } = useColorMode();
  const indicator: PositionProps & { _before: SystemStyleObject } = {
    pos: 'relative',
    _before: {
      content: "''",
      position: 'absolute',
      top: 0,
      right: 0,
      bgColor: 'orange.300',
      w: 2,
      h: 2,
      borderRadius: 'full',
      border: '2px',
      borderColor: 'chakra-body-bg',
    },
  };

  const popoverContentProps: PopoverContentProps = {
    boxShadow: '2xl',
    borderRadius: 'xl',
    border: 'none',
  };

  useEffect(() => {
    document
      .querySelector('meta[name=theme-color]')
      ?.setAttribute('content', colorMode === 'light' ? '#ffffff' : '#1a202c');
  }, [colorMode]);

  useEffect(() => {
    fetch('/json/notfications.json').then(async (res) => {
      setNotifications((await res.json()) as Notification[]);
    }, console.error);
  }, []);

  return (
    <Box
      as="header"
      pos="fixed"
      top={0}
      left={0}
      right={0}
      pt="env(safe-area-inset-top)"
      zIndex="docked"
      bgColor="chakra-body-bg"
    >
      <Box
        pl="env(safe-area-inset-left)"
        pr="env(safe-area-inset-right)"
        borderBottom="1px"
        borderColor="chakra-border-color"
        h={{ base: 14, md: 16 }}
      >
        <HStack h="full" px={4}>
          <Link to={routes.landing}>
            <HupassLogo />
          </Link>
          <Flex flex={1} justify="flex-end" direction="row" gap={2}>
            <IconButton
              aria-label="DisplayMode"
              icon={useColorModeValue(<IoMoonOutline />, <IoSunnyOutline />)}
              isRound
              onClick={toggleColorMode}
            />
            <Popover
              onClose={() => {
                setRead(notifications.map((notification) => notification.id));
              }}
            >
              <PopoverTrigger>
                <IconButton
                  aria-label="Notice"
                  icon={<IoNotificationsOutline />}
                  isRound
                  {...(!notifications.every((notification) =>
                    read().includes(notification.id)
                  ) && { ...indicator, colorScheme: 'orange' })}
                />
              </PopoverTrigger>
              <PopoverContent {...popoverContentProps}>
                <PopoverCloseButton />
                <PopoverHeader>お知らせ</PopoverHeader>
                <PopoverBody maxH={'calc(100dvh - 128px)'} overflow={'auto'}>
                  <VStack align="stretch" divider={<Divider />}>
                    {notifications.map((notification, i) => (
                      <Flex
                        key={notification.id + i}
                        gap={3}
                        rounded="md"
                        pos="relative"
                        {...(!read().includes(notification.id) && indicator)}
                      >
                        <Flex py={1}>
                          <Icon fontSize="2xl">
                            <IoInformationCircleOutline />
                          </Icon>
                        </Flex>
                        <Box flex={1}>
                          <Text fontSize="sm">{notification.title}</Text>
                          <Box fontSize={'xs'} color={'chakra-subtle-text'}>
                            <Markdown>{notification.body}</Markdown>
                          </Box>
                        </Box>
                      </Flex>
                    ))}
                  </VStack>
                </PopoverBody>
              </PopoverContent>
            </Popover>
            {/** User Menu */}
            <Popover isOpen={isOpen} onClose={onClose} onOpen={onOpen}>
              <PopoverTrigger>
                <IconButton
                  aria-label="User Menu"
                  icon={
                    userInfo ? (
                      <Avatar
                        name={userInfo.username}
                        src={userInfo.picture_url}
                        w="40px"
                        h="40px"
                      />
                    ) : (
                      <IoPersonOutline />
                    )
                  }
                  isRound
                />
              </PopoverTrigger>
              <PopoverContent {...popoverContentProps}>
                <PopoverCloseButton />
                <PopoverBody>
                  <Center>
                    <VStack py={2} gap={4}>
                      {userInfo ? (
                        <>
                          <Avatar size="xl" src={userInfo.picture_url} />
                          <Center flexDirection="column">
                            <Heading>{userInfo.username}</Heading>
                            <Text>{userInfo.email}</Text>
                          </Center>
                          <Button onClick={onLogout}>ログアウト</Button>
                        </>
                      ) : (
                        <>
                          <Icon fontSize="8xl" as={FcImport} />
                          <Heading>ログイン</Heading>
                          <Text>ログインしてデータを同期</Text>
                          <Button as={Link} to={routes.login} onClick={onClose}>
                            ログイン
                          </Button>
                        </>
                      )}
                    </VStack>
                  </Center>
                </PopoverBody>
              </PopoverContent>
            </Popover>
          </Flex>
        </HStack>
      </Box>
    </Box>
  );
}
