import {
  Button,
  Center,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  useDisclosure,
  Text,
} from '@chakra-ui/react';
import { Action as NavigationType } from '@remix-run/router';
import Footer from 'components/common/footer/Footer';
import Header from 'components/common/header/Header';
import Navigation from 'components/common/navigation/Navigation';
import { Suspense, memo, useEffect } from 'react';
import { Outlet, useLocation, useNavigationType } from 'react-router-dom';
import { atom, useRecoilValue } from 'recoil';
import { registerSW } from 'virtual:pwa-register';
import { toast } from 'components/common/toast/toast';
import { useCookies } from 'react-cookie';

export const defaultAppOptions = {
  header: true,
  footer: true,
  navigation: true,
};

export type AppOptions = typeof defaultAppOptions;

export const appOptionsState = atom<AppOptions>({
  key: 'appOptions',
  default: defaultAppOptions,
});

let ignoreUpdate = false;
export let sw: ServiceWorkerRegistration | undefined = undefined;

const UpdateConfirmModal = memo(function UpdateConfirmModal() {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const updateSW = registerSW({
    onNeedRefresh: () => {
      if (!ignoreUpdate) onOpen();
    },
    onRegisteredSW: (url, reg) => {
      sw = reg;
    },
  });

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>アプリのアップデートがあります</ModalHeader>
        <ModalBody>すぐにアップデートしてよろしいですか？</ModalBody>
        <ModalFooter gap={2} justifyContent={'flex-end'}>
          <Button
            onClick={() => {
              ignoreUpdate = true;
              onClose();
            }}
          >
            いいえ
          </Button>
          <Button onClick={() => void updateSW()} colorScheme="blue">
            はい
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
});

export default memo(function App() {
  const appOptions = useRecoilValue(appOptionsState);
  const { header, footer, navigation } = appOptions;
  const location = useLocation();
  const navigationType = useNavigationType();
  const [cookies, setCookie] = useCookies(['cookieAcceptedFlag']);
  const toastId = 'cookie-toast'; // cookieを表示するトーストのid

  useEffect(() => {
    if (navigationType === NavigationType.Push) window.scrollTo({ top: 0 });
  }, [location.pathname, navigationType]);

  // コンポーネントがマウントされた際にトーストでクッキー認証を行う
  useEffect(() => {
    // すでに認証済みなら表示しない
    if (!cookies.cookieAcceptedFlag) {
      toast({
        description: (
          <Text fontSize="sm">
            当ウェブサイトは、サイトの利便性向上のためクッキーを使用します。利用するブラウザの設定からいつでもクッキーの利用を停止できます。
          </Text>
        ),
        position: 'bottom',
        duration: null,
        id: toastId,
        onCloseComplete: () => {
          // Cookieにflag立てる
          setCookie('cookieAcceptedFlag', true, {
            maxAge: 60 * 60 * 24 * 365,
          });
          // トースト閉じる
          toast.close(toastId);
        },
      });
    }
  }, [cookies.cookieAcceptedFlag, setCookie]);

  return (
    <>
      <UpdateConfirmModal />
      <Flex
        minH="calc(100dvh - env(safe-area-inset-top) - env(safe-area-inset-bottom))"
        pt={header ? { base: 14, md: 16 } : 0}
        pl={navigation ? { base: 0, md: 16 } : 0}
        pb={navigation ? { base: 14, md: 0 } : 0}
        alignItems="stretch"
        direction="column"
      >
        {navigation && <Navigation />}
        {header && <Header />}
        <Suspense
          fallback={
            <Center>
              <Spinner />
            </Center>
          }
        >
          <Outlet />
        </Suspense>
        {footer && <Footer />}
      </Flex>
    </>
  );
});
