import { useEffect, useState } from 'react';

export const useTitle = (initialTitle?: string | (() => string)) => {
  const [title, setTitle] = useState(initialTitle ?? '');

  useEffect(() => {
    const original = document.getElementsByTagName('title')[0].innerText;
    document.getElementsByTagName('title')[0].innerText =
      `${title} - ${original}`;

    return () => {
      document.getElementsByTagName('title')[0].innerText = original;
    };
  }, [title]);

  return [title, setTitle];
};
