import { HStack, useColorModeValue } from '@chakra-ui/react';

export const useLogoColor = () => useColorModeValue('blue.700', 'blue.200');

export default function HupassLogo({
  logo,
  text,
  size,
}: {
  logo?: boolean;
  text?: boolean;
  size?: number;
}) {
  return (
    <HStack color={useLogoColor()}>
      {(logo ?? true) && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={39.6 * (size ?? 1)}
          height={39.6 * (size ?? 1)}
          viewBox="0 0 104.775 104.775"
        >
          <g
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="3.826"
          >
            <path d="M46.057 44.147l5.8 3.348v6.699l-5.8 3.346-5.8-3.348v-6.7zM85.822 27.891c-6.791 11.75-21.194 16.133-32.17 9.795-10.976-6.337-14.375-20.998-7.596-32.754m39.758 68.872c-13.573-.005-24.568-10.286-24.566-22.957-.003-12.676 10.999-22.954 24.568-22.96M46.056 4.933c6.78 11.754 3.383 26.415-7.6 32.756-10.972 6.338-25.37 1.95-32.16-9.802m-.002 45.916c6.785-11.753 21.187-16.136 32.164-9.799S52.843 85 46.054 96.758m0 0c-6.777-11.76-3.383-26.415 7.603-32.762 10.975-6.329 25.376-1.946 32.16 9.801M6.297 27.886c13.571.005 24.577 10.283 24.58 22.958-.006 12.678-11.01 22.947-24.583 22.958" />
          </g>
        </svg>
      )}
      {(text ?? true) && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={90.4 * (size ?? 1)}
          height={39.6 * (size ?? 1)}
          viewBox="104.775 0 239.182 104.775"
        >
          <g
            style={{ lineHeight: 1.25 }}
            fill="currentColor"
            strokeWidth="1.508"
            fontSize="60.305"
          >
            <path d="M109.479 28.863q0-.972.677-1.649.707-.707 1.679-.707.971 0 1.649.707.706.677.706 1.649v18.845h23.557V28.863q0-.972.677-1.649.707-.707 1.679-.707.971 0 1.649.707.706.677.706 1.649v42.402q0 .972-.706 1.678-.678.678-1.65.678-.971 0-1.678-.678-.677-.706-.677-1.678V52.42H114.19v18.845q0 .972-.706 1.678-.678.678-1.65.678-.971 0-1.678-.678-.677-.706-.677-1.678zm69.314 42.402q0 .972-.706 1.678-.678.678-1.65.678-.971 0-1.678-.678-.677-.706-.677-1.678v-1.914q-4.417 4.27-10.6 4.27-6.331 0-10.837-4.476-4.475-4.505-4.475-10.836V42.997q0-.972.677-1.649.707-.707 1.678-.707.972 0 1.65.707.706.677.706 1.649v15.312q0 4.387 3.092 7.509 3.121 3.091 7.508 3.091 4.388 0 7.48-3.091 3.121-3.122 3.121-7.51v-15.31q0-.972.677-1.649.707-.707 1.679-.707.971 0 1.649.707.706.677.706 1.649zm10.423-14.134q0 4.888 3.445 8.333 3.445 3.445 8.333 3.445t8.333-3.445q3.446-3.445 3.446-8.333t-3.446-8.333q-3.445-3.445-8.333-3.445t-8.333 3.445q-3.445 3.445-3.445 8.333zm0 28.268q0 .972-.707 1.649-.677.707-1.649.707-.971 0-1.678-.707-.677-.677-.677-1.649V57.131q0-6.831 4.829-11.66 4.829-4.83 11.66-4.83 6.832 0 11.66 4.83 4.83 4.829 4.83 11.66t-4.83 11.66q-4.828 4.83-11.66 4.83-6.831 0-11.66-4.83-.06-.058-.118-.117zm50.039-40.046q-4.888 0-8.333 3.445-3.446 3.445-3.446 8.333t3.446 8.333q3.445 3.445 8.333 3.445t8.333-3.445q3.445-3.445 3.445-8.333t-3.445-8.333q-3.445-3.445-8.333-3.445zm11.778 23.32q-4.947 4.948-11.778 4.948-6.832 0-11.66-4.83-4.83-4.829-4.83-11.66t4.83-11.66q4.828-4.83 11.66-4.83 6.831 0 11.66 4.83 4.83 4.829 4.83 11.66v14.134q0 .972-.707 1.678-.678.678-1.65.678-.971 0-1.678-.678-.677-.706-.677-1.678z" />
            <path d="M270.104 45.398q-2.027 0-3.474 1.447-1.447 1.375-1.447 3.474 0 2.098 1.447 3.546 1.447 1.375 3.474 1.375h9.19q1.882 0 3.546.723 1.665.724 2.895 2.027 1.302 1.23 2.026 2.894.724 1.665.724 3.546 0 1.882-.724 3.618-.724 1.665-2.026 2.895-1.23 1.23-2.895 1.954-1.664.724-3.546.724h-16.21q-.94 0-1.592-.58-.579-.65-.579-1.519 0-.868.58-1.447.65-.652 1.591-.652h16.21q2.026 0 3.474-1.447 1.447-1.447 1.447-3.546 0-2.026-1.447-3.473-1.448-1.448-3.474-1.448h-9.19q-1.882 0-3.619-.723-1.664-.724-2.894-1.954-1.23-1.23-1.954-2.895-.724-1.737-.724-3.618 0-1.882.724-3.546.724-1.665 1.954-2.895 1.23-1.302 2.894-2.026 1.737-.724 3.619-.724h16.21q.94 0 1.52.651.65.58.65 1.448 0 .94-.65 1.592-.58.579-1.52.579zm32.453 0q-2.026 0-3.474 1.447-1.447 1.375-1.447 3.474 0 2.098 1.447 3.546 1.448 1.375 3.474 1.375h9.19q1.882 0 3.546.723 1.665.724 2.895 2.027 1.303 1.23 2.026 2.894.724 1.665.724 3.546 0 1.882-.724 3.618-.723 1.665-2.026 2.895-1.23 1.23-2.895 1.954-1.664.724-3.546.724h-16.21q-.94 0-1.592-.58-.579-.65-.579-1.519 0-.868.58-1.447.65-.652 1.591-.652h16.21q2.027 0 3.474-1.447t1.447-3.546q0-2.026-1.447-3.473-1.447-1.448-3.474-1.448h-9.19q-1.882 0-3.618-.723-1.665-.724-2.895-1.954t-1.954-2.895q-.724-1.737-.724-3.618 0-1.882.724-3.546.724-1.665 1.954-2.895 1.23-1.302 2.895-2.026 1.736-.724 3.618-.724h16.21q.94 0 1.52.651.65.58.65 1.448 0 .94-.65 1.592-.58.579-1.52.579z" />
            <circle cx="197.699" cy="54.495" r="4.87" />
            <circle cx="235.96" cy="54.495" r="4.87" />
          </g>
        </svg>
      )}
    </HStack>
  );
}
