import { Badge, CardBody, Text, useColorModeValue } from '@chakra-ui/react';
import { type MyClassInfo } from 'app/hupassApi';
import routes from 'app/routes';
import { memo } from 'react';
import { Link } from 'react-router-dom';
import ClassSubtitle from './ClassSubtitle';
import ClassTitle from './ClassTitle';
import TimetableClassCard from './TimetableClassCard';

export interface MyClassCardProps {
  year: number;
  semester: string;
  period: string;
  myClass: MyClassInfo;
}

const MyClassCard = memo(function MyClassCard(props: MyClassCardProps) {
  const { year, semester, period, myClass } = props;
  const bgColor = useColorModeValue('blackAlpha.50', 'whiteAplha.50');

  return (
    <Link
      to={`${routes.timetable_detail.replace(
        ':id',
        String(myClass?.cls.id)
      )}?year=${year}&semester=${semester}&period=${period}`}
    >
      <TimetableClassCard classColor={myClass.color}>
        <CardBody
          p={{ base: 1, md: 2 }}
          display={'flex'}
          flexDir={'column'}
          gap={1}
        >
          <ClassTitle>{myClass.c_subject ?? myClass.cls.subject}</ClassTitle>
          <ClassSubtitle>{myClass.c_theme ?? myClass.cls.theme}</ClassSubtitle>
          <Text fontSize={['2xs', 'xs', 'sm']} noOfLines={2}>
            {myClass.c_teacher ?? myClass.cls.teacher}
          </Text>
          <Badge
            fontSize={['2xs', 'xs', 'sm']}
            mt={'auto'}
            noOfLines={2}
            bgColor={bgColor}
            textAlign={'center'}
          >
            {myClass.c_place}
          </Badge>
        </CardBody>
      </TimetableClassCard>
    </Link>
  );
});

export default MyClassCard;
