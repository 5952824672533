import {
  Drawer,
  DrawerContent,
  DrawerOverlay,
  type DrawerProps,
} from '@chakra-ui/react';
import { memo } from 'react';

const BottomDrawer = memo(function BottomDrawer(props: DrawerProps) {
  const { children, ...otherProps } = props;

  return (
    <Drawer placement="bottom" trapFocus={false} {...otherProps}>
      <DrawerOverlay />
      <DrawerContent borderTopRadius={32} w="6xl" maxH="80dvh" mx="auto">
        {children}
      </DrawerContent>
    </Drawer>
  );
});

export default BottomDrawer;
