import {
  Button,
  Divider,
  Radio,
  RadioGroup,
  VStack,
  useDisclosure,
} from '@chakra-ui/react';
import { sortOptionsState } from 'app/recoil/sortOptions';
import BottomDrawer from 'components/common/drawer/BottomDrawer';
import BottomDrawerBody from 'components/common/drawer/BottomDrawerBody';
import BottomDrawerHeader from 'components/common/drawer/BottomDrawerHeader';
import { memo, useState } from 'react';
import { IoArrowDown, IoArrowUp, IoSwapVertical } from 'react-icons/io5';
import { useSearchParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

const SortOptions = memo(function SortOptions() {
  const [params, setParams] = useSearchParams();
  const [invert, setInvert] = useState(
    params.get('order_by')?.substring(0, 1) === '-'
  );
  const sortOptions = useRecoilValue(sortOptionsState);
  const { isOpen, onClose, onOpen } = useDisclosure();

  const handleChangeOrder = (order: string) => {
    params.set('order_by', order);
    setParams(params);
  };

  const defaultOrder = sortOptions.flatMap((option) =>
    option.default ? option.name : []
  )[0];

  return (
    <>
      <Button
        aria-label="並び替え選択"
        onClick={onOpen}
        w="full"
        leftIcon={<IoSwapVertical />}
        variant="link"
      >
        {sortOptions
          .flatMap((option) =>
            option.name === (params.get('order_by') ?? defaultOrder)
              ? option.label
              : []
          )[0]
          ?.replace(/\(.*\)/, '')}
      </Button>
      <BottomDrawer isOpen={isOpen} onClose={onClose}>
        <BottomDrawerHeader onClose={onClose}>並び替え</BottomDrawerHeader>
        <BottomDrawerBody>
          <VStack alignItems="stretch" spacing={4}>
            <Button
              leftIcon={invert ? <IoArrowUp /> : <IoArrowDown />}
              onClick={() => {
                setInvert(!invert);
                if (invert) {
                  handleChangeOrder(
                    (params.get('order_by') ?? defaultOrder).replace(/^-/, '')
                  );
                } else {
                  handleChangeOrder(
                    `-${params.get('order_by') ?? defaultOrder}`
                  );
                }
              }}
            >
              {invert ? '昇順' : '降順'}
            </Button>
            <Divider />
            <RadioGroup
              value={params.get('order_by') ?? defaultOrder}
              onChange={handleChangeOrder}
              gap={4}
            >
              <VStack alignItems="stretch">
                {sortOptions
                  .filter(
                    ({ name }) => invert === (name.substring(0, 1) === '-')
                  )
                  .map(({ label, name }) => (
                    <Radio key={name} value={name}>
                      {label.replace(/\(.*\)/, '')}
                    </Radio>
                  ))}
              </VStack>
            </RadioGroup>
          </VStack>
        </BottomDrawerBody>
      </BottomDrawer>
    </>
  );
});

export default SortOptions;
