import {
  ClassCard,
  type ClassCardProps,
} from 'components/common/card/ClassCard';
import { memo } from 'react';

const TimetableClassCard = memo(function TimetableClassCard(
  props: ClassCardProps
) {
  return <ClassCard borderRadius="md" isBgColor {...props} />;
});

export default TimetableClassCard;
