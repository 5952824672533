import { Box, Textarea, TextareaProps } from '@chakra-ui/react';
import {
  ChangeEventHandler,
  forwardRef,
  memo,
  useEffect,
  useRef,
  useState,
} from 'react';

const AutoResizeTextArea = forwardRef<HTMLTextAreaElement, TextareaProps>(
  function AutoResizeTextArea(props, ref) {
    const textareaRef = useRef<HTMLTextAreaElement>(null);
    const boxRef = useRef<HTMLDivElement>(null);
    const [text, setText] = useState(props.value ?? props.defaultValue ?? '');

    useEffect(() => {
      if (ref instanceof Function) return;
      const current = ref?.current ?? textareaRef.current;
      if (current === null || boxRef.current === null) return;
      boxRef.current.style.padding =
        getComputedStyle(current).getPropertyValue('padding');
      boxRef.current.style.outline =
        getComputedStyle(current).getPropertyValue('outline');
      boxRef.current.style.border =
        getComputedStyle(current).getPropertyValue('border');
      boxRef.current.style.minHeight =
        getComputedStyle(current).getPropertyValue('min-height');
      boxRef.current.style.fontSize =
        getComputedStyle(current).getPropertyValue('font-size');
      boxRef.current.style.fontWeight =
        getComputedStyle(current).getPropertyValue('font-weight');
      boxRef.current.style.fontFamily =
        getComputedStyle(current).getPropertyValue('font-family');
      boxRef.current.style.lineHeight =
        getComputedStyle(current).getPropertyValue('line-height');
    }, [ref, props]);

    useEffect(() => {
      if (props.value !== undefined) setText(props.value);
    }, [props.value]);

    const handleChange: ChangeEventHandler<HTMLTextAreaElement> = (e) => {
      props.onChange?.(e);
      if (props.value === undefined) setText(e.target.value);
    };

    return (
      <Box pos={'relative'}>
        <Textarea
          position={'absolute'}
          top={0}
          left={0}
          right={0}
          bottom={0}
          resize={'none'}
          height={'auto'}
          ref={ref ?? textareaRef}
          {...props}
          onChange={handleChange}
        />
        <Box visibility={'hidden'} whiteSpace={'pre-wrap'} ref={boxRef}>
          {String(text) + ' '}
        </Box>
      </Box>
    );
  }
);

export default memo(AutoResizeTextArea);
